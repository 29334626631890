nfb.define('views/screens/RecipePage',['utils', 'globals', './ContentScreen', 'controllers/Analytics'], function( utils, Globals, ContentScreen, Analytics ) {

	var module = function( assets, whichWoman ) {
		
		//Public vars
		this.mainCntr = $('#' + whichWoman + 'Recipe');


		//Private vars
		var $polaroid,
			$polaroidImg,
			$polaroidImgRoll,
			$polaroidOuter,
			$polaroidName,
			$forwardArrow = $('#forwardArrow'),
			$twitter,
			$facebook,
			$pinterest,
			$email,
			$print,
			titlecaseWoman,
			self = this;



		//Init
		//*******************************************************************************************************
		//Cache HTML Elements
		$polaroid			= this.mainCntr.find('.polaroid');
		$polaroidImg		= $polaroid.find('.inner');
		$polaroidImgRoll	= $polaroid.find('.innerRoll');
		$polaroidOuter		= $polaroid.find('.outer');
		$polaroidName		= $polaroid.find('.name');
		$bgCntr1			= this.mainCntr.find('.recipeBook');
		$bgCntr2			= this.mainCntr.find('.recipeBookInner');
		$bgCntr3			= this.mainCntr.find('.recipeBookInner2');
		$twitter			= this.mainCntr.find('.twitter');
		$facebook			= this.mainCntr.find('.facebook');
		$pinterest			= this.mainCntr.find('.pinterest');
		$email				= this.mainCntr.find('.email');
		$print				= this.mainCntr.find('.print');

		titlecaseWoman = whichWoman.substring(0, 1).toUpperCase() + whichWoman.substring(1);


		$twitter.attr('href', 'https://twitter.com/intent/tweet' + '?url=' + encodeURIComponent(window.location.href) + '&text=' + encodeURIComponent( $twitter.data('tweet') ) + '&hashtags=' + encodeURIComponent( $twitter.data('hashtags') ));
		$pinterest.attr('href', "//www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(window.urlDomain + '?sec=' + whichWoman + '-recipe') + "&media=" + encodeURIComponent( window.sharingAssetsPath + 'img/sharing/NFB-Bread-' + titlecaseWoman + '.jpg') + "&description=" + encodeURIComponent( this.mainCntr.find('H1').html() ) );
		$print.attr('href', window.assetsRoot + $print.attr('href'));

		$facebook.click( onClickFacebook );
		$twitter.click( onClickTwitter );
		$pinterest.click( onClickPinterest );
		$email.click( onClickEmail );
		$print.click( onClickPrint );

		//Apply the images
		if(assets) {
			$polaroidOuter.css('backgroundImage', 'url(' + assets.get('recipePagePolaroid') + ')');
			$polaroidImg.css('backgroundImage', 'url(' + assets.get('recipePagePolaroid' + titlecaseWoman) + ')');
			$polaroidImgRoll.css('backgroundImage', 'url(' + assets.get('recipePagePolaroid' + titlecaseWoman + 'Roll') + ')');
			$polaroidName.css('backgroundImage', 'url(' + assets.get('recipePagePolaroid' + titlecaseWoman + 'Title') + ')');
			$bgCntr1.css('backgroundImage', 'url(' + assets.get('recipePageBG') + ')');
			$bgCntr2.css('backgroundImage', 'url(' + assets.get('recipePageTop') + ')');
			$bgCntr3.css('backgroundImage', 'url(' + assets.get('recipePageBot') + ')');
		}

		$polaroid.hover( onHoverPolaroid );
		//*******************************************************************************************************


		function onHoverPolaroid(e) {
			$polaroidImgRoll.stop();
			if(e.type === 'mouseenter') {
				$polaroidImgRoll.fadeIn('slow');
			} else {
				$polaroidImgRoll.fadeOut('slow');
			}
		}



		//Public methods
		//*******************************************************************************************************
		this.start = function() {
			self.mainCntr.addClass('canScroll');

			TweenLite.killTweensOf($forwardArrow);
			TweenLite.to($forwardArrow, 0.6, {marginRight: Globals.scrollbarWidth + 'px'});
		};

		this.pause = function() {
			self.mainCntr.removeClass('canScroll');
		};

		this.resume = function() {
			self.mainCntr.addClass('canScroll');
		};
		//*******************************************************************************************************

		
		


		//Event Listeners
		//*******************************************************************************************************
		this.onResize = function(e) {
			
		};

		function onClickPinterest(e) {
			Analytics.trackSocial( 'pinterest', 'pin', whichWoman + '-recipe');
		}

		function onClickTwitter(e) {
			Analytics.trackSocial( 'twitter', 'tweet', whichWoman + '-recipe');
		}

		function onClickEmail(e) {
			Analytics.trackEvent('recipe', 'clicked email', whichWoman);
		}

		function onClickPrint(e) {
			Analytics.trackEvent('recipe', 'clicked print', whichWoman);
		}

		function onClickFacebook(e) {
			e.preventDefault();

			Analytics.trackSocial( 'facebook', 'share', whichWoman + '-recipe');

			if(FB) {
				FB.ui({
					method: 'share',
					href: window.urlDomain + '?sec=' + whichWoman + '-recipe'
				}, function(response){});
			}
		}
		//*******************************************************************************************************
	};

	utils.extend(module, ContentScreen);

	return module;
});
