nfb.define('views/screens/VideoPage',['utils', './ContentScreen', 'controllers/AudioController', 'controllers/Analytics', 'globals', 'spin', 'jplayer'], function( utils, ContentScreen, AudioController, Analytics, globals, Spinner ) {

	var module = function VideoPage( assets, whichWoman ) {
		//Public vars
		this.mainCntr = $('#' + whichWoman + 'Video');

		//Private vars
		var $video = this.mainCntr.find('.jp-jplayer'),
			$largePlayBtn = this.mainCntr.find('.largePlayButton'),
			$posterFrame = this.mainCntr.find('.posterFrame'),
			aspectRatio = {w:16, h:9},
			jplayerVid,
			$cntr = $('article'),
			wantsToPlay = false,
			videoReady = false,
			mediaSet = false,
			titlecaseWoman = whichWoman.substring(0, 1).toUpperCase() + whichWoman.substring(1),
			$window = $(window),
			spinnerCtn = this.mainCntr.find('.spinnerCtn')[0],
			$closeBtn = this.mainCntr.find('.close-video-btn'),
			spinner,
			lastTime = 0,
			spinnerTimeout,
			spinnerVis = false,
			self = this,
			lang = $('html.fr').length > 0 ? 'fr' : 'en',
			videoFilenameSuffix = (lang === 'fr') ? '_fr' : '';


		spinner = new Spinner({
			lines: 15, // The number of lines to draw
			length: 6, // The length of each line
			width: 2, // The line thickness
			radius: 6, // The radius of the inner circle
			corners: 1, // Corner roundness (0..1)
			rotate: 0, // The rotation offset
			direction: 1, // 1: clockwise, -1: counterclockwise
			color: '#fff', // #rgb or #rrggbb or array of colors
			speed: 1.3, // Rounds per second
			trail: 75, // Afterglow percentage
			shadow: true, // Whether to render a shadow
			hwaccel: true, // Whether to use hardware acceleration
			className: 'spinner', // The CSS class to assign to the spinner
			zIndex: 2e9, // The z-index (defaults to 2000000000)
			top: '50%', // Top position relative to parent
			left: '50%' // Left position relative to parent
		});

		if(globals.isMobile) {
			$posterFrame.css('backgroundImage', 'url(' + assets.get('videoPagePoster' + titlecaseWoman) + ')');
			$largePlayBtn.css('backgroundImage', 'url(' + assets.get('largePlayButton') + ')');	
		} else {
			$posterFrame.hide();
		}



		//Public methods
		//*******************************************************************************************************
		this.init = function() {
			var nativeVideoControlsNone = {};
			$.each($.jPlayer.prototype.options.nativeVideoControls, function(prop, value) {
				nativeVideoControlsNone[prop] = false;
			});
			
			$video.jPlayer({
				ready: function () {
					jplayerVid = $(this).data('jPlayer');
					videoReady = true;
					setMedia();
					if(wantsToPlay) self.start();
				},
				play: function() {
					if(globals.isMobile) Analytics.trackEvent('video', 'mobile clicked play', whichWoman);
					onPlay();
					spinnerTimeout = setTimeout(showSpinner, 500);
				},
				playing: function() {
					hideSpinner();
					hidePosterFrame();
				},
				pause: function() {
					console.log('paused');
					hideSpinner();
				},
				click: function(e) {
					if(! globals.isMobile) {
						if(e.jPlayer.status.paused) {
							self.resume();
						} else {
							self.pause();
						}	
					}
				},
				loadstart: function() {
					self.mainCntr.find('.jp-duration').text( $.jPlayer.convertTime( globals.videoDur[whichWoman] ) );
				},
				seeking: function() {
					console.log('seeking');
					showSpinner();
				},
				seeked: function() {
					console.log('seeked');
					hideSpinner();
				},
				emptied: function() {
					console.log('emptied');
				},
				waiting: function() {
					// console.log('waiting');
				},
				stalled: function() {
					console.log('stalled');
					// showSpinner();
				},
				canplay: function() {
					hideSpinner();
				},
				ended: function() {
					console.log('ended');
					Analytics.trackEvent('video', 'complete', whichWoman);
					hideSpinner();
					try {
						$video.find('video')[0].webkitExitFullscreen();
					} catch(e) {}
					self.mainCntr.trigger(self.VIDEO_END);
				},
				timeupdate: function(e) {
					// console.log('timeupdate');
					if(e.jPlayer.status.currentTime !== lastTime) {
						hideSpinner();
					}
					lastTime = e.jPlayer.status.currentTime;
				},
				cssSelectorAncestor: '#' + this.mainCntr.find('.jp-video').attr('id'),
				swfPath: window.assetsRoot + "swfs/",
				// smoothPlayBar: true, //Enabling this caused a bug in the seekbar in the Flash-fallback
				keyEnabled: true,
				nativeVideoControls: nativeVideoControlsNone,
				keyBindings: {
					play: {
						key: 32, // space
						fn: function(f) {
							if(f.status.paused) {
								self.resume();
							} else {
								self.pause();
							}
						}
					},
					fullScreen: {
						key: 13, // enter
						fn: function(f) {}
					},
					muted: {
						key: 8, // backspace
						fn: function(f) {}
					},
					volumeUp: {
						key: 38, // UP
						fn: function(f) {}
					},
					volumeDown: {
						key: 40, // DOWN
						fn: function(f) {}
					}
				},
				volume: globals.volume,
				globalVolume: true,
				preload: 'auto',
				// solution: 'flash, html', //Uncomment to test Flash-fallback
				supplied: "m4v, webmv"
			});

			$window.on(self.PLAYING, onOtherVideoPlaying);
			$window.on( AudioController.VOLUME_UPDATE, onVolumeUpdate );
		};



		this.start = function() {
			wantsToPlay = true;
			if(videoReady) {
				$video.jPlayer('focus');
				
				setMedia();

				if(! globals.isMobile) {
					$video.jPlayer('play');
					$closeBtn.addClass('active');
					$closeBtn.click( onClickCloseBtn );
				} else {
					$posterFrame.click( onClickPosterFrame );
				}

				mediaSet = true;
			}
		};


		this.pause = function() {
			$video.jPlayer('pause');
			wantsToPlay = false;
		};

		this.resume = function() {
			$video.jPlayer('play');
			wantsToPlay = true;
		};

		this.kill = function() {
			$video.jPlayer('destroy');
			if(globals.isMobile) {
				$posterFrame.show();
				$largePlayBtn.show();
			}
			hideSpinner();
			jplayerVid = null;
			wantsToPlay = false;
			videoReady = false;
			mediaSet = false;
			$closeBtn.removeClass('active');
			$closeBtn.off( 'click', onClickCloseBtn );
			$window.off(self.PLAYING, onOtherVideoPlaying);
			$window.off( AudioController.VOLUME_UPDATE, onVolumeUpdate );
		};
		//*******************************************************************************************************





		// Private methods
		//*******************************************************************************************************

		function setMedia() {
			if(! mediaSet) {
				var quality = (globals.forceVideoQuality !== null) ? globals.forceVideoQuality : globals.videoQuality;
				
				$video.jPlayer("setMedia", {
					m4v:	window.videoPath + whichWoman + '_' + quality + videoFilenameSuffix + '.mp4',
					webmv:	window.videoPath + whichWoman + '_' + quality + videoFilenameSuffix + '.webm',
					poster: assets.get('videoPagePoster' + titlecaseWoman)
				});
			}
			mediaSet = true;
		}


		function showSpinner() {
			spinnerVis = true;
			spinner.spin(spinnerCtn);
		}

		function hideSpinner() {
			if(spinnerVis) {
				spinnerVis = false;
				spinner.stop();
			}
			clearInterval(spinnerTimeout);
		}

		function hidePosterFrame() {
			$posterFrame.fadeOut('fast');
		}
		//*******************************************************************************************************





		// Events Listeners
		//*******************************************************************************************************
		function onPlay(e) {
			$posterFrame.off( 'click', onClickPosterFrame );
			$largePlayBtn.fadeOut('fast');

			var alreadyTracked = false;
			for(var i=0; i < globals.videosWatched.length; i++) {
				if(globals.videosWatched[i] === whichWoman) {
					alreadyTracked = true;
					break;
				}
			}
			if(! alreadyTracked) {
				globals.videosWatched.push(whichWoman);	
			}
			
			self.mainCntr.trigger(self.PLAYING);
		}

		function onOtherVideoPlaying(e) {
			if(e.target !== self.mainCntr[0]) {
				self.kill();
			}
		}

		function onVolumeUpdate(e) {
			$video.jPlayer('volume', globals.volume);
		}

		function onClickPosterFrame(e) {
			$video.jPlayer('play');
			$posterFrame.off( 'click', onClickPosterFrame );
			$largePlayBtn.fadeOut('fast');
			showSpinner();
		}


		function onClickCloseBtn(e) {
			$closeBtn.removeClass('active');
			$closeBtn.off( 'click', onClickCloseBtn );
			Analytics.trackEvent('video', 'clicked close button', whichWoman);
			$window.trigger( self.CLOSE_VIDEO );
		}

		this.onResize = function(e) {
			var w = $cntr.outerWidth(),
				h = $cntr.outerHeight(),
				scale = Math.max(w / aspectRatio.w, h / aspectRatio.h),
				destW = Math.round(aspectRatio.w * scale),
				destH = Math.round(aspectRatio.h * scale),
				destTop;

			if(! globals.isMobile || true) {
				destTop = Math.round((destH - h)/-2);
				// need to make sure subtitles stay on screen
				if(lang === 'fr') {
					destTop = Math.min(destTop, h - (destH * 680 / 720));
				}

				$video.css({width: destW + 'px',
					  		height: destH + 'px',
					  		top: destTop + 'px',
					  		left: Math.round((destW - w)/-2) + 'px'});

				$video.jPlayer( {
					//redefines the jPlayer fullscreen dimension (width and height)
					size: {width: destW + 'px', height: destH + 'px'}
				} );

			} else {
				h -= 37;
				scale = Math.min(w / aspectRatio.w, h / aspectRatio.h);
				destW = Math.round(aspectRatio.w * scale);
				destH = Math.round(aspectRatio.h * scale);
				$video.css({width: destW + 'px',
					  		height: destH + 'px',
					  		top: Math.round(37 + (h-37)/2 - destH/2) + 'px',
					  		left: Math.round(w/2 - destW/2) + 'px'});

				$video.jPlayer( {
					//redefines the jPlayer fullscreen dimension (width and height)
					size: {width: destW + 'px', height: destH + 'px'}
				} );
			}

			$posterFrame.css({width: destW + 'px',
								height: destH + 'px',
								top: destTop,
								left: Math.round((destW - w)/-2) + 'px'});

			$('.cropGuide').css({width: destW + 'px',
					  		height: destH + 'px',
					  		top: destTop + 'px',
					  		left: Math.round((destW - w)/-2) + 'px'});
			
		};
		//*******************************************************************************************************
	};

	utils.extend(module, ContentScreen);

	module.prototype.PLAYING = module.PLAYING = 'videoPlaying.bread';
	module.prototype.VIDEO_END = module.VIDEO_END = 'videoEnd.bread';
	module.prototype.CLOSE_VIDEO = module.CLOSE_VIDEO = 'closeVideo.bread';

	return module;
});


