nfb.define('views/screens/IntroLoader',['utils', 'globals', './ContentScreen', 'controllers/Analytics', 'timelineLite'], function( utils, Globals, ContentScreen, Analytics ) {

	var module = function( assets ) {
		//Public vars
		this.navDepth = 0;

		//Private vars
		var $cup,
			$flour,
			$introTxtCtn,
			$skipIntro,
			$skipIntroRoll,
			$window = $(window),
			percentLoaded = 0,
			loadedStep = 0,
			allAssetsLoaded = false,
			animComplete = false,
			txtAnim,
			timer,
			allCompleteDelay,
			restarting = false,
			self = this;


		this.fakePercentLoaded = 0;
		


		//Init
		//*******************************************************************************************************
		this.mainCntr = $('#loadingPage');
		$cup = this.mainCntr.find('.measuringCup');
		$introTxtCtn = $('.introTxtCtn');
		$skipIntro = this.mainCntr.find('.skip');
		$skipIntroRoll = this.mainCntr.find('.skipRoll');

		//Apply the images
		$skipIntro.css('backgroundImage', 'url(' + assets.get('introSkip') + ')');
		$skipIntroRoll.css('backgroundImage', 'url(' + assets.get('introSkipRoll') + ')');
		$cup.css('backgroundImage', 'url(' + assets.get('introCup') + ')');
		$('#flour10').css('backgroundImage', 'url(' + assets.get('introFlour_10') + ')');
		$('#flour20').css('backgroundImage', 'url(' + assets.get('introFlour_20') + ')');
		$('#flour30').css('backgroundImage', 'url(' + assets.get('introFlour_30') + ')');
		$('#flour40').css('backgroundImage', 'url(' + assets.get('introFlour_40') + ')');
		$('#flour50').css('backgroundImage', 'url(' + assets.get('introFlour_50') + ')');
		$('#flour60').css('backgroundImage', 'url(' + assets.get('introFlour_60') + ')');
		$('#flour70').css('backgroundImage', 'url(' + assets.get('introFlour_70') + ')');
		$('#flour80').css('backgroundImage', 'url(' + assets.get('introFlour_80') + ')');
		$('#flour90').css('backgroundImage', 'url(' + assets.get('introFlour_90') + ')');
		$('#flour100').css('backgroundImage', 'url(' + assets.get('introFlour_100') + ')');
		//*******************************************************************************************************




		//Public methods
		//*******************************************************************************************************
		this.start = function() {
			initTxtAnim();

			if(! restarting) {
				assets.addEventListener(assets.PROGRESS, onPreloadProgress);
				assets.addEventListener(assets.COMPLETE, onPreloadComplete);
				assets.preloadMain();

				var dur = Globals.allowSkipIntro ? 0 : txtAnim.duration() - 2;
				TweenLite.to(self, dur, {fakePercentLoaded:1, ease:Linear.easeNone});
				timer = setInterval( updateLoadProgress, 100 );
			}

			restarting = false;
		};

		this.pause = function() {
			if(txtAnim) txtAnim.pause();
		};

		this.resume = function() {
			if(txtAnim) txtAnim.resume();
		};


		this.restart = function() {
			restarting = true;
			if(txtAnim) {
				txtAnim.clear();
				txtAnim = null;
			}

			var clone = $introTxtCtn.clone();
			clone.removeAttr('style')
				.find('.introLine, .introLine > span').removeAttr('style');
			$introTxtCtn.before( clone ).remove();
			$introTxtCtn = $('.introTxtCtn');

			Globals.videosWatched = [];

			if(allAssetsLoaded) {
				$skipIntro.click( onClickSkip )
					.stop().show()
					.addClass('canFade');
			}
		};
		//*******************************************************************************************************




		// Private methods
		//*******************************************************************************************************
		function initTxtAnim() {
			var fadeDist = 20,
				fadeDur = 1.5,
				fadeOutDur = 1,
				fadeEase = Power2.easeOut,
				fadeOutEase = Power2.easeIn,
				fadeInOutEase = Power2.easeInOut,
				delayBtwnLines = 0.6;

			$introTxtCtn.find('.introLine > span').css('top', fadeDist + 'px');

			txtAnim = new TimelineLite();
			txtAnim.delay(0.5);
			txtAnim.add( [
							TweenLite.to($('#sixRecipes'), fadeDur, {opacity:1, top:0, ease:fadeEase, onStart:showLine, onStartParams:[$('#introLine1')]}),
							TweenLite.to($('#sixWomen'), fadeDur, {opacity:1, top:0, ease:fadeEase})
				]);

			txtAnim.add( [
							TweenLite.to($('#oneCommonStory'), fadeDur, {opacity:1, top:0, ease:fadeEase}),
							TweenLite.to($('#shapedByFamily'), fadeDur, {opacity:1, top:0, ease:fadeEase}),
							TweenLite.to($('#community'), fadeDur, {opacity:1, top:0, ease:fadeEase})
				], "+=" + delayBtwnLines);
			

			txtAnim.add( TweenLite.to($('#introLine1'), fadeOutDur, {opacity:0, ease:fadeOutEase, delay:3, onComplete:killLine, onCompleteParams:[$('#introLine1')]}) );

			txtAnim.add( [
							TweenLite.to($('#aStoryConnected'), fadeDur, {opacity:1, top:0, ease:fadeEase, onStart:showLine, onStartParams:[$('#introLine2')]}),
							TweenLite.to($('#sixDifferentLifetimes'), fadeDur, {opacity:1, top:0, ease:fadeEase}),
							TweenLite.to($('#hardshipAndJoy'), fadeDur, {opacity:1, top:0, ease:fadeEase})
				]);

			txtAnim.add( [
							TweenLite.to($('#sharedSkills'), fadeDur, {opacity:1, top:0, ease:fadeEase}),
							TweenLite.to($('#masteredOverDecades'), fadeDur, {opacity:1, top:0, ease:fadeEase}),
							TweenLite.to($('#ofRepetition'), fadeDur, {opacity:1, top:0, ease:fadeEase})
				], "+=" + delayBtwnLines);

			txtAnim.add( TweenLite.to($('#introLine2'), fadeOutDur, {opacity:0, ease:fadeOutEase, delay:3, onComplete:killLine, onCompleteParams:[$('#introLine2')]}) );

			txtAnim.add( [
							TweenLite.to($('#narratives'), fadeDur, {opacity:1, top:0, ease:fadeEase, onStart:showLine, onStartParams:[$('#introLine3')]}),
							TweenLite.to($('#remarkableStory'), fadeDur, {opacity:1, top:0, ease:fadeEase})
				], '+=' + delayBtwnLines);

			txtAnim.add( TweenLite.to($('#titleCard'), 0, {opacity:1, ease:fadeEase, delay:2, onComplete:onCompleteTxtAnim}) );

			txtAnim.add( [
							TweenLite.to($('#narratives'), fadeOutDur, {opacity:0, ease:fadeOutEase}),
							TweenLite.to($('#remarkableStory'), fadeOutDur, {opacity:0, ease:fadeOutEase})
				], '+=1');
		}

		function showLine( whichLine ) {
			whichLine.css('display', 'table-cell');
		}

		function killLine( whichLine ) {
			whichLine.hide();
		}

		function onCompleteTxtAnim() {
			Analytics.trackEvent('intro', 'intro anim complete');
			animComplete = true;
			if(allAssetsLoaded) allCompleteDelay = setTimeout( onAllComplete, 3000 );
		}

		function onAllComplete() {
			Analytics.trackEvent('Interactive production', '/interactive/bread/auto_begin');
			$skipIntro.off('click', onClickSkip);
			clearTimeout(allCompleteDelay);
			$window.trigger( self.INTRO_ANIM_COMPLETE );
		}

		function removeIntro() {
			$introTxtCtn.remove();
		}
		//*******************************************************************************************************




		//Event Listeners
		//*******************************************************************************************************

		function updateLoadProgress() {
			var percent = Math.min(percentLoaded, self.fakePercentLoaded),
				dur = 0.3,
				ease = Linear.easeNone;

			if(percent >= 0.1 && loadedStep < 0.1) {
				loadedStep = 0.1;
				TweenLite.to($('#flour10'), dur, {opacity:1, ease:ease});
			} else if(percent >= 0.2 && loadedStep < 0.2) {
				loadedStep = 0.2;
				TweenLite.killTweensOf($('#flour10'));
				TweenLite.to($('#flour10'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour20'), dur, {opacity:1, ease:ease});
			} else if(percent >= 0.3 && loadedStep < 0.3) {
				loadedStep = 0.3;
				TweenLite.killTweensOf($('#flour20'));
				TweenLite.to($('#flour20'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour30'), dur, {opacity:1, ease:ease});
			} else if(percent >= 0.4 && loadedStep < 0.4) {
				loadedStep = 0.4;
				TweenLite.killTweensOf($('#flour30'));
				TweenLite.to($('#flour30'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour40'), dur, {opacity:1, ease:ease});
			} else if(percent >= 0.5 && loadedStep < 0.5) {
				loadedStep = 0.5;
				TweenLite.killTweensOf($('#flour40'));
				TweenLite.to($('#flour40'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour50'), dur, {opacity:1, ease:ease});
			} else if(percent >= 0.6 && loadedStep < 0.6) {
				loadedStep = 0.6;
				TweenLite.killTweensOf($('#flour50'));
				TweenLite.to($('#flour50'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour60'), dur, {opacity:1, ease:ease});
			} else if(percent >= 0.7 && loadedStep < 0.7) {
				loadedStep = 0.7;
				TweenLite.killTweensOf($('#flour60'));
				TweenLite.to($('#flour60'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour70'), dur, {opacity:1, ease:ease});
			} else if(percent >= 0.8 && loadedStep < 0.8) {
				loadedStep = 0.8;
				TweenLite.killTweensOf($('#flour70'));
				TweenLite.to($('#flour70'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour80'), dur, {opacity:1, ease:ease});
			} else if(percent >= 0.9 && loadedStep < 0.9) {
				loadedStep = 0.9;
				TweenLite.killTweensOf($('#flour80'));
				TweenLite.to($('#flour80'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour90'), dur, {opacity:1, ease:ease});
			} else if(percent === 1 && loadedStep < 1) {
				loadedStep = 1;
				TweenLite.killTweensOf($('#flour90'));
				TweenLite.to($('#flour90'), dur, {opacity:0, ease:ease});
				TweenLite.to($('#flour100'), dur, {opacity:1, ease:ease});
			}
		}

		//===============================================================
		function onPreloadProgress( e ) {
			percentLoaded = e.progress;
			updateLoadProgress();
		}

		function onPreloadComplete( e ) {
			allAssetsLoaded = true;
			$window.trigger( self.ALL_ASSETS_LOADED );

			if(Globals.allowSkipIntro && ! animComplete) {
				$skipIntro.click( onClickSkip )
					.fadeIn('slow', function() {
						$(this).addClass('canFade');
					});
			}
			
			if(animComplete) {
				setTimeout( onAllComplete, 5000 );
			}
		}
		//===============================================================



		//===============================================================
		function onClickSkip(e) {
			Analytics.trackEvent('Interactive production', '/interactive/bread/user_begin');

			self.pause();
			onAllComplete();
		}
		//===============================================================



		//===============================================================
		this.onResize = function(e) {
			$introTxtCtn.css('height', $cup.offset().top - 30 + 'px');
		};
		//===============================================================

		//*******************************************************************************************************
		
	};

	utils.extend(module, ContentScreen);

	//Events
	module.prototype.CLICK_CONTINUE	= module.CLICK_CONTINUE = 'clickContinue';
	module.prototype.ALL_ASSETS_LOADED = module.ALL_ASSETS_LOADED = 'allAssetsLoaded';
	module.prototype.INTRO_ANIM_COMPLETE = module.INTRO_ANIM_COMPLETE = 'introAnimComplete';

	return module;
});
