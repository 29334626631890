nfb.define(
				'controllers/NavMngr',['./BackgroundMngr', './Analytics', 'views/Subnav',  'views/screens/IntroLoader', 'views/screens/MainMenu', 'views/screens/VideoPage', 'views/screens/RecipePage', 'views/screens/AboutPage', 'views/screens/CreditsPage', 'EventDispatcher', 'utils', 'globals'],
	function(     BackgroundMngr,     Analytics,     Subnav,          IntroLoader,                 MainMenu,                 VideoPage,                 RecipePage,                 AboutPage,                 CreditsPage,                 EventDispatcher,   utils,   globals ) {

	var module = function( assets ) {
		// Public vars
		this.initialSection = null;
		this.forceNextAnimDir = null;
		this.canNavigate = true;

		// Private vars
		var $backArrow = $('#backArrow'),
			$forwardArrow = $('#forwardArrow'),
			$footersCntr = $('#footerSections'),
			previousSection,
			currentSection,
			currentFooter,
			previousFooter,
			backgroundMngr = new BackgroundMngr( assets ),
			sectionName,
			sectionType,
			subnav,
			previousSectionType,
			allSections = {},
			$window = $(window),
			self = this;



		// Public methods
		//*******************************************************************************************************

		//Navigate to a new section
		//===============================================================
		this.gotoSection = function( section, slowAnim ) {
			if(this.canNavigate) {
				animOnNewSection( section, slowAnim );
			}

			self.forceNextAnimDir = null;
		};
		//===============================================================



		//Close a footer section
		//===============================================================
		this.closeFooterSection = function() {
			if(currentFooter && this.canNavigate) {
				currentFooter.addEventListener(currentFooter.COMPLETE, onCompleteAnimOffFooter);
				currentFooter.anim( globals.animDirections.TO_BOTTOM );
				previousFooter = null;

				self.dispatchEvent(self.GOING_TO_SECTION_TYPE, {sectionType: previousSectionType});
			}
		};
		//===============================================================



		//Make sure that we're passing a valid section from the url, and that it's not just rubbish the user typed in
		//===============================================================
		this.isValidSection = function( section ) {
			for(var prop in globals.sections) {
				if(globals.sections[prop] === section) {
					return true;
				}
			}

			return false;
		};
		//===============================================================



		//===============================================================
		this.onVideoEnd = function(e) {
			subnav.gotoRecipe();
		};
		//===============================================================



		//===============================================================
		this.initSubnav = function() {
			subnav = new Subnav( assets );
		};
		//===============================================================



		//===============================================================
		this.startOver = function() {
			if(self.canNavigate) {
				self.closeFooterSection();

				// We're not in the Preloader
				if(currentSection !== allSections[globals.sections.INTRO_LOADER]) {
					self.forceNextAnimDir = globals.animDirections.FROM_LEFT;
					animOnNewSection(globals.sections.INTRO_LOADER);
					currentSection.restart();
					self.forceNextAnimDir = null;

					if(allSections[globals.sections.MAIN_MENU]) allSections[globals.sections.MAIN_MENU].viewIndex = 1;

				} else {
					currentSection.restart();
					currentSection.start();
				}	
			}
			
		};
		//===============================================================

		//*******************************************************************************************************




		
		// Private methods
		//*******************************************************************************************************

		// Initialize and build a section
		//===============================================================
		function initSection( section ) {
			for(var key in allSections) {
				if(key === section) {
					return;
				}
			}

			switch(section) {
				case globals.sections.INTRO_LOADER:
					allSections[section] = new IntroLoader( assets );
					break;
				case globals.sections.MAIN_MENU:
					allSections[section] = new MainMenu( assets );
					break;
				case globals.sections.ANNE_VIDEO:
					allSections[section] = new VideoPage( assets, 'anne' );
					break;
				case globals.sections.ANNE_RECIPE:
					allSections[section] = new RecipePage( assets, 'anne' );
					break;
				case globals.sections.CARRIE_VIDEO:
					allSections[section] = new VideoPage( assets, 'carrie' );
					break;
				case globals.sections.CARRIE_RECIPE:
					allSections[section] = new RecipePage( assets, 'carrie' );
					break;
				case globals.sections.VIDYA_VIDEO:
					allSections[section] = new VideoPage( assets, 'vidya' );
					break;
				case globals.sections.VIDYA_RECIPE:
					allSections[section] = new RecipePage( assets, 'vidya' );
					break;
				case globals.sections.VERA_VIDEO:
					allSections[section] = new VideoPage( assets, 'vera' );
					break;
				case globals.sections.VERA_RECIPE:
					allSections[section] = new RecipePage( assets, 'vera' );
					break;
				case globals.sections.WILMA_VIDEO:
					allSections[section] = new VideoPage( assets, 'wilma' );
					break;
				case globals.sections.WILMA_RECIPE:
					allSections[section] = new RecipePage( assets, 'wilma' );
					break;
				case globals.sections.CHARLOTTE_VIDEO:
					allSections[section] = new VideoPage( assets, 'charlotte' );
					break;
				case globals.sections.CHARLOTTE_RECIPE:
					allSections[section] = new RecipePage( assets, 'charlotte' );
					break;
				case globals.sections.ABOUT:
					allSections[section] = new AboutPage( assets );
					break;
				case globals.sections.CREDITS:
					allSections[section] = new CreditsPage( assets );
					break;
			}
		}
		//===============================================================



		//Instantiate and bring on a new section
		//===============================================================
		function animOnNewSection( section, slowAnim ) {
			var dir,
				sectionFound = false,
				footerFound = false;

			initSection( section );

			switch( section ) {
				case '':
				case globals.sections.INTRO_LOADER:
					sectionFound = true;
					currentSection = allSections[globals.sections.INTRO_LOADER];
					backgroundMngr.setBG( globals.sections.INTRO_LOADER );
					sectionType = 'intro';
					break;

				case globals.sections.MAIN_MENU:
					sectionFound = true;
					currentSection = allSections[section];
					currentSection.updateVisitedVideos();
					backgroundMngr.setBG( section );
					sectionType = 'menu';
					break;

				case globals.sections.ANNE_VIDEO:
				case globals.sections.CARRIE_VIDEO:
				case globals.sections.VIDYA_VIDEO:
				case globals.sections.VERA_VIDEO:
				case globals.sections.WILMA_VIDEO:
				case globals.sections.CHARLOTTE_VIDEO:
					sectionFound = true;
					currentSection = allSections[section];
					backgroundMngr.setBG( globals.sections.MAIN_MENU );
					sectionType = 'video';
					break;

				case globals.sections.ANNE_RECIPE:
				case globals.sections.CARRIE_RECIPE:
				case globals.sections.VIDYA_RECIPE:
				case globals.sections.VERA_RECIPE:
				case globals.sections.WILMA_RECIPE:
				case globals.sections.CHARLOTTE_RECIPE:
					sectionFound = true;
					currentSection = allSections[section];
					backgroundMngr.setBG( section );
					sectionType = 'recipe';
					break;

				case globals.sections.ABOUT:
				case globals.sections.CREDITS:
					footerFound = true;
					currentFooter = allSections[section];
					sectionType = 'footer';
					break;
			}

			// Break out if we're trying to navigate to the same section we're already at
			if((sectionFound && currentSection === previousSection) ||
				(footerFound && currentFooter === previousFooter)) {
				return;
			}

			sectionName = section;
			self.canNavigate = false;

			if(previousSection !== undefined) {
				previousSection.pause();
			}

			if(sectionFound || footerFound) {
				self.dispatchEvent(self.GOING_TO_SECTION_TYPE, {sectionType: sectionType});
			}

			Analytics.trackPage(section);

			//Only if this is a valid section to navigate to, and it's not the same section we're already in
			if(sectionFound) {

				//Tell the subnav what to do
				if(subnav) {
					if( (sectionType === 'video' || sectionType === 'recipe') && sectionType === previousSectionType ) {
						// subnav.hideTabs();
						subnav.updateNav( sectionName, true );

					} else if(sectionType !== 'video' && sectionType !== 'recipe') {
						if(sectionName === globals.sections.MAIN_MENU) subnav.updateNav( sectionName, true );
						subnav.hideAll();

					} else if( (sectionType === 'video' || sectionType === 'recipe') && sectionType !== previousSectionType ) {
						subnav.updateNav( sectionName, true );
					}
				}

				//Reset the position of the Next arrow
				if(sectionType !== 'recipe') {
					TweenLite.to($forwardArrow, 0.6, {marginRight: 0});
				}

				//Decide the direction it should animate in
				if(previousSection === undefined) {
					dir = globals.animDirections.FADE_IN;
				
				} else if(self.forceNextAnimDir !== null) {
					dir = self.forceNextAnimDir;

				} else {
					dir = globals.animDirections.FROM_RIGHT;
				}

				//Anim on the new section and background
				currentSection.addEventListener(currentSection.COMPLETE, onCompleteAnimSection);
				currentSection.init();
				currentSection.anim( dir, slowAnim );
				backgroundMngr.anim( dir, slowAnim );
				
				//Anim off the previous section
				if(previousSection !== undefined && dir === globals.animDirections.FROM_RIGHT) {
					previousSection.anim( globals.animDirections.TO_LEFT, slowAnim );

				} else if(previousSection !== undefined && dir === globals.animDirections.FROM_LEFT) {
					previousSection.anim( globals.animDirections.TO_RIGHT, slowAnim );
				}

				previousSectionType = sectionType;
			

			//We're opening a Footer section. Slightly different logic
			} else if(footerFound) {
				if(previousFooter && previousFooter !== currentFooter) {
					previousFooter.anim( globals.animDirections.TO_BOTTOM );
					previousFooter.removeEventListener(previousFooter.COMPLETE, onCompleteAnimOffFooter);
				}

				if(currentSection) currentSection.pause();
				
				$footersCntr.show();

				//Make it on top
				currentFooter.mainCntr.detach().appendTo($footersCntr);
				
				currentFooter.addEventListener(currentFooter.COMPLETE, onCompleteAnimFooter);
				currentFooter.init();
				currentFooter.anim( globals.animDirections.FROM_BOTTOM );
			}
		}
		//===============================================================

		//*******************************************************************************************************





		//Event Listeners
		//*******************************************************************************************************

		//===============================================================
		function onCompleteAnimSection( e ) {
			currentSection.removeEventListener(currentSection.COMPLETE, onCompleteAnimSection);
			currentSection.start();

			self.canNavigate = true;

			// if(previousSection) previousSection.kill();

			if(sectionType === 'video' || sectionType === 'recipe') {
				subnav.updateNav( sectionName, false );
			}
			
			previousSection = currentSection;
		}
		//===============================================================



		//===============================================================
		function onCompleteAnimFooter( e ) {
			currentFooter.removeEventListener(currentFooter.COMPLETE, onCompleteAnimFooter);
			currentFooter.start();

			self.canNavigate = true;
			
			previousFooter = currentFooter;
		}
		//===============================================================



		//===============================================================
		function onCompleteAnimOffFooter( e ) {
			$footersCntr.hide();
			e.currentTarget.removeEventListener(e.currentTarget.COMPLETE, onCompleteAnimOffFooter);
			currentFooter = null;
			if(previousSection) previousSection.resume();
		}
		//===============================================================
		

		//*******************************************************************************************************		
	};

	utils.extend(module, EventDispatcher);

	//Events
	module.prototype.GOING_TO_SECTION_TYPE = module.GOING_TO_SECTION_TYPE = 'goingToSectionType';

	return module;
});
