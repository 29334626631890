nfb.define('views/screens/Background',['utils', './ContentScreen'], function(utils, ContentScreen) {

	var module = function( htmlID, imgSrc ) {
		this.mainCntr = $(htmlID);
		this.mainCntr.css('background-image', 'url(' + imgSrc + ')');
		this.sectionType = "background";

		this.onResize = function(e) {

		};
	};

	utils.extend(module, ContentScreen);

	return module;
});
