nfb.define('globals',[],function() {

	return {
		sections: {
			INTRO_LOADER:		'intro',
			MAIN_MENU:			'menu',
			ANNE_VIDEO:			'anne-video',
			ANNE_RECIPE:		'anne-recipe',
			CARRIE_VIDEO:		'carrie-video',
			CARRIE_RECIPE:		'carrie-recipe',
			VIDYA_VIDEO:		'vidya-video',
			VIDYA_RECIPE:		'vidya-recipe',
			VERA_VIDEO:			'vera-video',
			VERA_RECIPE:		'vera-recipe',
			WILMA_VIDEO:		'wilma-video',
			WILMA_RECIPE:		'wilma-recipe',
			CHARLOTTE_VIDEO:	'charlotte-video',
			CHARLOTTE_RECIPE:	'charlotte-recipe',
			ABOUT:				'about',
			CREDITS:			'credits'
		},

		animDirections: {
			FROM_RIGHT:		'fromRight',
			FROM_LEFT:		'fromLeft',
			FROM_BOTTOM:	'fromBottom',
			TO_RIGHT:		'toRight',
			TO_LEFT:		'toLeft',
			TO_BOTTOM:		'toBottom',
			FADE_IN:		'fadeIn'
		},

		videoDur: {
			'anne':			3 * 60 + 37,
			'carrie':		5 * 60 + 13,
			'charlotte':	3 * 60 + 21,
			'vera':			4 * 60 + 52,
			'vidya':		4 * 60 + 29,
			'wilma':		5 * 60 + 18
		},

		womenOrder: ['anne', 'carrie', 'vidya', 'vera', 'wilma', 'charlotte'],

		videoQuality: 'medium',
		forceVideoQuality: null,
		videosWatched: [],

		volume: 1,

		shouldShowAnimJPGs: false,
		allowSkipIntro: false,
		isMobile: false,

		scrollbarWidth: 0
	};
});
