nfb.define('controllers/AudioController',['globals', 'soundJS'], function( globals ) {

	var module = function() {
		var MAX_BG_VOL = 0.5,
			bgMusic,
			audioPath = window.assetsRoot + "audio/",
			manifest = [
				{id:"BGMusic", src:"bread_bgAudio.ogg"}
			],
			muted = false,
			$window = $(window),
			videoPlaying = false,
			self = this;

		this.bgVolume = 0;
		this.globalVolume = 0;
		this.globalMute = false;

		


		this.setVideoPlaying = function( isVideoPlaying ) {
			videoPlaying = isVideoPlaying;

			var destVol = videoPlaying ? 0 : globals.volume;

			if(TweenLite) {
				TweenLite.to(self, 1, {bgVolume:destVol, ease:Linear.easeNone, onUpdate:setBGVolume});
			} else {
				bgVolume = destVol;
				setBGVolume();
			}
		};

		this.mute = function() {
			if(TweenLite) {
				TweenLite.killTweensOf(self);
				TweenLite.to(self, 1, {globalVolume:0, ease:Linear.easeNone, onUpdate:setGlobalVolume});
			} else {
				globalVolume = 0;
				setGlobalVolume();
			}
		};

		this.unmute = function() {
			if(TweenLite) {
				TweenLite.killTweensOf(self);
				TweenLite.to(self, 1, {globalVolume:1, ease:Linear.easeNone, onUpdate:setGlobalVolume});
			} else {
				globalVolume = 1;
				setGlobalVolume();
			}
		};


		init();


		function init() {
			if(! globals.isMobile) {
				createjs.Sound.alternateExtensions = ["mp3"];
				createjs.Sound.addEventListener("fileload", createjs.proxy(handleLoad, (this)) );
				createjs.Sound.registerManifest(manifest, audioPath);
			} else {
				self.unmute();
			}
		}

		function handleLoad(event) {
			bgMusic = createjs.Sound.play(event.src, {loop: -1});
			self.unmute();
		}

		function setGlobalVolume() {
			globals.volume = self.globalVolume;
			if((videoPlaying && muted) || (! videoPlaying)) {
				self.bgVolume = self.globalVolume;
				setBGVolume();
			}
			$window.trigger(self.VOLUME_UPDATE);
		}

		function setBGVolume() {
			if(bgMusic) bgMusic.volume = self.bgVolume * MAX_BG_VOL;
		}
	};

	//Events
	module.prototype.VOLUME_UPDATE = module.VOLUME_UPDATE = 'volumeUpdate';

	return module;
});
