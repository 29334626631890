nfb.define('templates',['handlebars'], function(Handlebars) {

this["JST"] = this["JST"] || {};

this["JST"]["recipe"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", self=this, escapeExpression=this.escapeExpression;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n							";
  if (helper = helpers.ingredientsHTML) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.ingredientsHTML); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n						";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n							";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.ingredients), {hash:{},inverse:self.noop,fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						";
  return buffer;
  }
function program4(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n								<ul class=\"ingredients\">\n									";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.ingredients), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n								</ul>\n							";
  return buffer;
  }
function program5(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n									<li>";
  stack1 = (typeof depth0 === functionType ? depth0.apply(depth0) : depth0);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</li>\n									";
  return buffer;
  }

function program7(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n							<li>";
  stack1 = (typeof depth0 === functionType ? depth0.apply(depth0) : depth0);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</li>\n							";
  return buffer;
  }

function program9(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n							<hr>\n							<p class=\"footnote\">";
  if (helper = helpers.footnote) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.footnote); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</p>\n						";
  return buffer;
  }

function program11(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n						<ul class=\"footnote ingredients\">\n							";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.footnoteIngredients), {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						</ul>\n						";
  return buffer;
  }

  buffer += "<div class=\"recipeBook-ctn\">\n	<div class=\"recipeBook\">\n		<div class=\"recipeBookInner\">\n			<div class=\"recipeBookInner2\">\n				<div class=\"leftPage\">\n					<div class=\"polaroid\">\n						<div class=\"aspectRatio polaroidAspect\"></div>\n						<div class=\"inner\">\n							<div class=\"innerRoll\"></div>\n							<!--div class=\"audioScrubber\">\n								<div class=\"audioScrubberInner\"></div>\n							</div>\n							<div class=\"audioBtn\"></div-->\n						</div>\n						<div class=\"outer\"></div>\n						<div class=\"name\"></div>\n					</div>\n\n					<div class=\"about\">\n						";
  if (helper = helpers.about) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.about); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n						<hr class=\"bioHR\">\n					</div>\n\n					<div class=\"sharingBtns\">\n						<ul class=\"shareBtnsList\">\n							<li><a href=\"#\" class=\"pinterest\" target=\"_blank\" title=\"";
  if (helper = helpers.tooltipPinterest) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.tooltipPinterest); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">Pinterest</a></li>\n							<li><a href=\"#\" data-tweet=\"";
  if (helper = helpers.tweet) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.tweet); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" data-hashtags=\"nfb,bread\" target=\"_blank\" class=\"twitter\" title=\"";
  if (helper = helpers.tooltipTwitter) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.tooltipTwitter); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">Twitter</a></li>\n							<li><a href=\"#\" class=\"facebook\" title=\"";
  if (helper = helpers.tooltipFacebook) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.tooltipFacebook); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">Facebook</a></li>\n							<li><a href=\"mailto:%20?subject=";
  if (helper = helpers.emailSubject) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.emailSubject); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "&body=";
  if (helper = helpers.emailBody) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.emailBody); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  if (helper = helpers.emailBodyLinkDomain) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.emailBodyLinkDomain); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1);
  if (helper = helpers.emailBodyLink) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.emailBodyLink); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"email\" title=\"";
  if (helper = helpers.tooltipEmail) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.tooltipEmail); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">Email</a></li>\n							<!--li><a href=\"#\" class=\"download\" title=\"Download recipe\">Download</a></li-->\n							<li><a href=\"";
  if (helper = helpers.pdfLink) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.pdfLink); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" target=\"_blank\" class=\"print\" title=\"";
  if (helper = helpers.tooltipPrint) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.tooltipPrint); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">Print</a></li>\n						</ul>\n					</div>\n				</div>\n				\n				<div class=\"rightPage\">\n					<h1>";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h1>\n\n					<hr>\n\n					<div class=\"recipe\">\n						<p>";
  if (helper = helpers['yield']) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0['yield']); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</p>\n						\n\n						";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.ingredientsHTML), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "						\n						\n						<hr>\n\n						";
  if (helper = helpers.recipePreamble) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.recipePreamble); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n						<ol class=\"recipeInstructions\">\n							";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.recipe), {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						</ol>\n\n						";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.footnote), {hash:{},inverse:self.noop,fn:self.program(9, program9, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n						";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.footnoteIngredients), {hash:{},inverse:self.noop,fn:self.program(11, program11, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n					</div>\n				</div>				\n			</div> <!-- recipeBookInner2 -->\n		</div> <!-- recipeBookInner -->\n	</div> <!-- recipeBook -->\n</div> <!-- recipeBook-ctn -->";
  return buffer;
  });

this["JST"]["video"] = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div id=\"jp_container_";
  if (helper = helpers.index) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.index); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"jp-video\">\n	<div class=\"jp-type-single\">\n		<div id=\"jquery_jplayer_";
  if (helper = helpers.index) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.index); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"jp-jplayer\"></div>\n		\n		<div class=\"jp-gui\">\n			<div class=\"jp-interface\">\n				<div class=\"currentTime\">\n					<span class=\"jp-current-time\"></span> / <span class=\"jp-duration\"></span>\n				</div>\n				<div class=\"jp-controls-holder\">\n					<ul class=\"jp-controls\">\n						<li><a href=\"javascript:;\" class=\"jp-play\" tabindex=\"1\">play</a></li>\n						<li><a href=\"javascript:;\" class=\"jp-pause\" tabindex=\"1\">pause</a></li>\n					</ul>\n				</div>\n				<div class=\"jp-progress\">\n					<div class=\"jp-seek-bar\">\n						<div class=\"jp-play-bar\"></div>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n<div class=\"close-video-btn\"></div>\n\n<div class=\"posterFrame\">\n	<div class=\"largePlayButton\"></div>\n</div>\n\n<div class=\"spinnerCtn\"></div>\n\n<!--div class=\"cropGuide\"></div-->";
  return buffer;
  });

return this["JST"];

});
