nfb.define('views/screens/MainMenu',['utils', 'globals', './ContentScreen', 'controllers/AnimatedJPGMngr', 'views/AnimatedJPG'], function( utils, globals, ContentScreen, AnimatedJPGMngr, AnimatedJPG ) {

	var module = function( assets ) {

		//Public vars		
		this.navDepth = 1;

		//Private vars
		var $polaroidsCntr = $("#polaroidsCntr"),
			$polaroidOutlines = $('#polaroidOutlines'),
			$polaroidOutlinesPortrait = $('#polaroidOutlinesPortrait'),
			$polaroidLinks = $polaroidsCntr.find('.mainMenuLink'),
			animJPGMngr,
			polaroidImages = [],
			polaroidImagesAnimated = [],
			numAnimJPGsLoaded = 0,
			rollImages = [],
			animJPGs = [], 
			portrait = false,
			paused = true,
			imgMapCoords = [[8,14,321,9,327,271,10,277],
							[344,9,657,9,657,274,343,272],
							[676,14,989,17,987,279,674,275],
							[11,274,322,280,322,540,6,539],
							[338,287,651,275,662,537,345,548],
							[673,282,988,277,995,540,678,544]],
			imgMapCoordsPortrait = [[6,13,320,9,328,270,10,276,6,13],
									[343,8,656,9,657,269,343,272,343,8],
									[8,290,325,289,321,555,7,554,8,290],
									[341,289,655,290,653,553,341,550,341,289],
									[5,580,318,565,327,829,11,841,5,580],
									[338,570,652,563,657,827,341,832,338,570]],
			self = this;


		//Init
		//*******************************************************************************************************
		this.mainCntr = $('#mainMenuPage');

		//Cache references to polaroid rollover images
		for(var i = 1; i <= 6; i++) {
			rollImages.push( $("#polaroid" + i + " .polaroidRoll") );
		}
		for(i = 1; i <= 6; i++) {
			polaroidImages.push( $("#polaroid" + i + " .polaroidBG") );
		}
		for(i = 1; i <= 6; i++) {
			polaroidImagesAnimated.push( $("#polaroid" + i + " .polaroidBGAnim") );
		}

		//Apply all the images
		rollImages[0].attr('src', assets.get('mainMenuAnne') );
		rollImages[1].attr('src', assets.get('mainMenuCarrie') );
		rollImages[2].attr('src', assets.get('mainMenuVidya') );
		rollImages[3].attr('src', assets.get('mainMenuVera') );
		rollImages[4].attr('src', assets.get('mainMenuWilma') );
		rollImages[5].attr('src', assets.get('mainMenuCharlotte') );
		$polaroidsCntr.find('.ribbon').css('background-image', 'url(' + assets.get('mainMenuRibbon') + ')' );
		$polaroidOutlines.attr('src', assets.get('mainMenuPolaroids'));
		$polaroidOutlinesPortrait.attr('src', assets.get('mainMenuPolaroidsPortrait'));


		$polaroidLinks.click( onClickPolaroid );
		if(! globals.isMobile) $polaroidLinks.hover( onRollOverPolaroid, onRollOutPolaroid );
		//*******************************************************************************************************


		



		//Public methods
		//*******************************************************************************************************
		this.init = function() {
			self.viewIndex = Math.min(globals.videosWatched.length + 1, 7);
			updateImages();
		};



		this.start = function() {
			paused = false;
			if(self.viewIndex === 1 && globals.shouldShowAnimJPGs) {
				initAnimJPGs();
			}
		};


		this.pause = function() {
			paused = true;
			if(animJPGMngr) {
				animJPGMngr.pause();
			}
		};

		this.resume = function() {
			paused = false;
			if(animJPGMngr) {
				animJPGMngr.play();
			}
		};

		this.updateVisitedVideos = function() {
			$polaroidsCntr.find('.tint, .ribbon').hide();

			for(var i=0; i < globals.videosWatched.length; i++) {
				// $polaroidsCntr.find('.tint.' + globals.videosWatched[i] + ', .ribbon.' + globals.videosWatched[i]).show();
				$polaroidsCntr.find('.ribbon.' + globals.videosWatched[i]).show();
			}
		};
		//*******************************************************************************************************




		//Private methods
		//*******************************************************************************************************
		function updateImages() {
			var index = self.viewIndex,
				assetID,
				i;

			if(animJPGMngr) {
				animJPGMngr.kill();
				animJPGMngr = null;

				for(i = 1; i <= 6; i++) {
					polaroidImagesAnimated[i-1].attr('src', '').css('visibility', 'hidden');
				}
			}

			for(i = 1; i <= 6; i++) {
				assetID = 'mainMenu' + utils.capitalize( globals.womenOrder[i-1] );
				if(index > 1) assetID += index;

				polaroidImages[i-1].removeAttr('style')
								   .attr('src', assets.get(assetID))
								   .attr('width', '100%')
								   .attr('height', '100%')
								   .css({top: 0, left: 0});
			}

			// self.viewIndex++;
			// if(self.viewIndex > 6) self.viewIndex = 1;
		}



		function initAnimJPGs() {
			numAnimJPGsLoaded = 0;

			var assetID;
			for(var i = 1; i <= 6; i++) {
				assetID = 'mainMenu' + utils.capitalize( globals.womenOrder[i-1] ) + '1_anim';
				polaroidImagesAnimated[i-1].removeAttr('style')
					.attr('src', assets.get(assetID))
					.attr('width', '100%')
					.attr('height', '100%')
					.css({top: 0, left: 0, visibility: 'hidden'})
					.load( onLoadedAnimJPG );
			}

			var w = 290,
				h = 207,
				frames = 174,
				loops = true;

			AnimatedJPGMngr.defaultFPS = 10;

			animJPGs = [];

			var anne = new AnimatedJPG( polaroidImagesAnimated[0], w, h, frames, 7, 19, loops );
			anne.duplicateFrames.push( [72, 73] );
			anne.duplicateFrames.push( [94, 141] );
			animJPGs.push(anne);

			var carrie = new AnimatedJPG( polaroidImagesAnimated[1], w, h, frames, 5, 32, loops );
			carrie.duplicateFrames.push( [1, 17] );
			animJPGs.push(carrie);

			var vidya = new AnimatedJPG( polaroidImagesAnimated[2], w, h, frames, 3, 37, loops );
			vidya.duplicateFrames.push( [1, 57] );
			vidya.duplicateFrames.push( [163, 170] );
			animJPGs.push(vidya);

			var vera = new AnimatedJPG( polaroidImagesAnimated[3], w, h, frames, 3, 35, loops );
			vera.duplicateFrames.push( [1, 67] );
			vera.duplicateFrames.push( [143, 147] );
			animJPGs.push(vera);

			var wilma = new AnimatedJPG( polaroidImagesAnimated[4], w, h, frames, 3, 35, loops );
			wilma.duplicateFrames.push( [1, 61] );
			wilma.duplicateFrames.push( [162, 170] );
			animJPGs.push(wilma);

			var charlotte = new AnimatedJPG( polaroidImagesAnimated[5], w, h, frames, 3, 34, loops );
			charlotte.duplicateFrames.push( [1, 13] );
			charlotte.duplicateFrames.push( [80, 82] );
			charlotte.duplicateFrames.push( [83, 85] );
			charlotte.duplicateFrames.push( [113, 170] );
			animJPGs.push(charlotte);

			animJPGMngr = new AnimatedJPGMngr();
			animJPGMngr.addJPGAnim(anne);
			animJPGMngr.addJPGAnim(carrie);
			animJPGMngr.addJPGAnim(vidya);
			animJPGMngr.addJPGAnim(vera);
			animJPGMngr.addJPGAnim(wilma);
			animJPGMngr.addJPGAnim(charlotte);

			// self.onResize();
			//animJPGMngr.play();
			// console.log('anim jpgs play');
		}

		function onLoadedAnimJPG() {
			console.log('load');
			numAnimJPGsLoaded++;
			if(numAnimJPGsLoaded === 6) {
				self.onResize();
				for(var i = 1; i <= 6; i++) {
					polaroidImagesAnimated[i-1].css('visibility', 'visible');
				}
				if(! paused && animJPGMngr) animJPGMngr.play();
			}
		}
		//*******************************************************************************************************



		//Event Listeners
		//*******************************************************************************************************
		function onRollOverPolaroid(e) {
			if(self.viewIndex > 1) {
				showHidePolaroidRollover( $(this).data('index'), true );
			}
		}



		function onRollOutPolaroid(e) {
			if(self.viewIndex > 1) {
				showHidePolaroidRollover( $(this).data('index'), false );
			}
		}



		function showHidePolaroidRollover( index, show ) {
			if(show) {
				rollImages[index-1].stop().fadeIn('slow');
			} else {
				rollImages[index-1].stop().fadeOut('slow');
			}
		}



		function onClickPolaroid(e) {
			e.preventDefault();
			$(window).trigger( self.CLICK_POLAROID, [$(this).attr('href')] );
		}



		this.onResize = function( e ) {
			var winW = $(window).width() - (self.POLAROIDS_CNTR_PADDING * 2),
				winH = $(window).height() - (self.POLAROIDS_CNTR_PADDING * 2),
				scale, w, h, mTop;

			if(winW >= winH) {
				$polaroidsCntr.removeClass('portrait');
				scale = Math.min(winW / self.DEFAULT_POLAROIDS_CNTR_WIDTH, winH / self.DEFAULT_POLAROIDS_CNTR_HEIGHT);
				scale = Math.min(scale, 1);
				w = Math.ceil(self.DEFAULT_POLAROIDS_CNTR_WIDTH * scale);
				h = Math.ceil(self.DEFAULT_POLAROIDS_CNTR_HEIGHT * scale);
				mTop = self.DEFAULT_POLAROIDS_CNTR_HEIGHT * scale / -2;
				portrait = false;
			} else {
				$polaroidsCntr.addClass('portrait');
				scale = Math.min(winW / self.DEFAULT_POLAROIDS_CNTR_WIDTH_PORTRAIT, winH / self.DEFAULT_POLAROIDS_CNTR_HEIGHT_PORTRAIT);
				scale = Math.min(scale, 1);
				w = Math.ceil(self.DEFAULT_POLAROIDS_CNTR_WIDTH_PORTRAIT * scale);
				h = Math.ceil(self.DEFAULT_POLAROIDS_CNTR_HEIGHT_PORTRAIT * scale);
				mTop = self.DEFAULT_POLAROIDS_CNTR_HEIGHT_PORTRAIT * scale / -2;
				portrait = true;
			}
			
			$polaroidsCntr.css("width", w + "px")
				.css("height", h + "px")
				.css('margin-top', mTop + 'px');
			
			if(animJPGMngr && animJPGs && animJPGs.length > 0) {
				for(var i = 1; i <= 6; i++) {
					animJPGs[i-1].setScale(scale);
				}	
			}
		};
		//*******************************************************************************************************	
	};

	utils.extend(module, ContentScreen);

	//Static constants
	module.prototype.DEFAULT_POLAROIDS_CNTR_WIDTH			= 1001;
	module.prototype.DEFAULT_POLAROIDS_CNTR_HEIGHT			= 560;
	module.prototype.DEFAULT_POLAROIDS_CNTR_WIDTH_PORTRAIT	= 662;
	module.prototype.DEFAULT_POLAROIDS_CNTR_HEIGHT_PORTRAIT	= 850;
	module.prototype.POLAROIDS_CNTR_PADDING					= 40;
	
	//Statics
	module.prototype.viewIndex = module.prototype.viewIndex || 1;

	//Events
	module.prototype.ROLLOVER_POLAROID = module.ROLLOVER_POLAROID = 'rolloverPolaroid';
	module.prototype.ROLLOUT_POLAROID = module.ROLLOUT_POLAROID= 'rolloutPolaroid';
	module.prototype.CLICK_POLAROID = module.CLICK_POLAROID= 'clickPolaroid';

	return module;
});
