nfb.define('controllers/Analytics',['jquery', 'nfbFramework'], function($) {

	return {
		trackPage: function(url, delay) {
			//console.log('do: trackPage( ' + url + ' )');
			$.onf_nfb.stats.trackPageview( url, delay );
		},

		trackEvent: function(cat, action, label, value, delay) {
			//console.log('do: trackEvent( ' + cat + ', ' + action + ', ' + label + ', ' + value + ', ' + delay + ' )');
			$.onf_nfb.stats.trackEvent( cat, action, label, value, delay );
		},

		trackSocial: function(network, socialAction, opt_target, opt_pagePath) {
			//console.log('do: trackSocial( ' + network + ', ' + socialAction + ', ' + opt_target + ', ' + opt_pagePath + ' )');
			$.onf_nfb.stats.trackSocial( network, socialAction, opt_target, opt_pagePath );
		}
	};
});
