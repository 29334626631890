nfb.define('controllers/Assets',['EventDispatcher', './Analytics', 'utils', 'globals', 'preloadJS'], function(EventDispatcher, Analytics, utils, Globals) {

	var module = function() {
		//Public vars
		this.preloaderLoaded = false;
		this.allLoaded = false;


		//Private vars
		var JSON_ID = 'copyJSON',
			MAIN_ASSETS_FILESIZE_BYTES = Globals.shouldShowAnimJPGs ? 16919451 : 7296747, //annoyingly, this has to be calculated manually (bytes)
			BANDWIDTH_MED = 1000, //kilobits per seconds
			BANDWIDTH_HIGH = 2000,
			BANDWIDTH_SUPER = 4200,
			preloaderAssets,
			mainAssets,
			preloaderManifest,
			mainManifest,
			startTime,
			cb = '?cb=' + (new Date()).getTime(),
			self = this,
			lang = $('html.fr').length > 0 ? 'fr' : 'en';

		preloaderManifest = [
			{src: window.assetsRoot + "img/bgs/introBG.jpg",						id:"introBG"},
			{src: window.assetsRoot + "img/intro/cup.png",							id:"introCup"},
			{src: window.assetsRoot + "img/intro/bread.png",						id:"introBread"},
			{src: window.assetsRoot + "img/intro/flour_10.png",						id:"introFlour_10"},
			{src: window.assetsRoot + "img/intro/flour_20.png",						id:"introFlour_20"},
			{src: window.assetsRoot + "img/intro/flour_30.png",						id:"introFlour_30"},
			{src: window.assetsRoot + "img/intro/flour_40.png",						id:"introFlour_40"},
			{src: window.assetsRoot + "img/intro/flour_50.png",						id:"introFlour_50"},
			{src: window.assetsRoot + "img/intro/flour_60.png",						id:"introFlour_60"},
			{src: window.assetsRoot + "img/intro/flour_70.png",						id:"introFlour_70"},
			{src: window.assetsRoot + "img/intro/flour_80.png",						id:"introFlour_80"},
			{src: window.assetsRoot + "img/intro/flour_90.png",						id:"introFlour_90"},
			{src: window.assetsRoot + "img/intro/flour_100.png",					id:"introFlour_100"}
		];

		mainManifest = [
			{src: window.assetsRoot + "css/extraFonts.css",							id:"fontsCSS"},

			//Backgrounds
			{src: window.assetsRoot + "img/bgs/cuttingBoard.jpg",					id:"cuttingBoard"},
			{src: window.assetsRoot + "img/bgs/aboutBG.jpg",						id:"aboutBG"},
			{src: window.assetsRoot + "img/bgs/creditsBG.jpg",						id:"creditsBG"},

			//Main Menu
			{src: window.assetsRoot + "img/bradyBunch/anne.jpg",					id:"mainMenuAnne"},
			{src: window.assetsRoot + "img/bradyBunch/carrie.jpg",					id:"mainMenuCarrie"},
			{src: window.assetsRoot + "img/bradyBunch/charlotte.jpg",				id:"mainMenuCharlotte"},
			{src: window.assetsRoot + "img/bradyBunch/vera.jpg",					id:"mainMenuVera"},
			{src: window.assetsRoot + "img/bradyBunch/vidya.jpg",					id:"mainMenuVidya"},
			{src: window.assetsRoot + "img/bradyBunch/wilma.jpg",					id:"mainMenuWilma"},
			{src: window.assetsRoot + "img/bradyBunch/ribbon.png",					id:"mainMenuRibbon"},
			{src: window.assetsRoot + "img/bradyBunch/polaroids.png",				id:"mainMenuPolaroids"},
			{src: window.assetsRoot + "img/bradyBunch/polaroids_portrait.png",		id:"mainMenuPolaroidsPortrait"},
			
			/*{src: window.assetsRoot + "img/bradyBunch/seq1/anne_static.jpg",		id:"mainMenuAnne1"},
			{src: window.assetsRoot + "img/bradyBunch/seq1/carrie_static.jpg",		id:"mainMenuCarrie1"},
			{src: window.assetsRoot + "img/bradyBunch/seq1/charlotte_static.jpg",	id:"mainMenuCharlotte1"},
			{src: window.assetsRoot + "img/bradyBunch/seq1/vera_static.jpg",		id:"mainMenuVera1"},
			{src: window.assetsRoot + "img/bradyBunch/seq1/vidya_static.jpg",		id:"mainMenuVidya1"},
			{src: window.assetsRoot + "img/bradyBunch/seq1/wilma_static.jpg",		id:"mainMenuWilma1"},*/

			{src: window.assetsRoot + "img/bradyBunch/seq2/anne.jpg",				id:"mainMenuAnne2"},
			{src: window.assetsRoot + "img/bradyBunch/seq2/carrie.jpg",				id:"mainMenuCarrie2"},
			{src: window.assetsRoot + "img/bradyBunch/seq2/charlotte.jpg",			id:"mainMenuCharlotte2"},
			{src: window.assetsRoot + "img/bradyBunch/seq2/vera.jpg",				id:"mainMenuVera2"},
			{src: window.assetsRoot + "img/bradyBunch/seq2/vidya.jpg",				id:"mainMenuVidya2"},
			{src: window.assetsRoot + "img/bradyBunch/seq2/wilma.jpg",				id:"mainMenuWilma2"},
			{src: window.assetsRoot + "img/bradyBunch/seq3/anne.jpg",				id:"mainMenuAnne3"},
			{src: window.assetsRoot + "img/bradyBunch/seq3/carrie.jpg",				id:"mainMenuCarrie3"},
			{src: window.assetsRoot + "img/bradyBunch/seq3/charlotte.jpg",			id:"mainMenuCharlotte3"},
			{src: window.assetsRoot + "img/bradyBunch/seq3/vera.jpg",				id:"mainMenuVera3"},
			{src: window.assetsRoot + "img/bradyBunch/seq3/vidya.jpg",				id:"mainMenuVidya3"},
			{src: window.assetsRoot + "img/bradyBunch/seq3/wilma.jpg",				id:"mainMenuWilma3"},
			{src: window.assetsRoot + "img/bradyBunch/seq4/anne.jpg",				id:"mainMenuAnne4"},
			{src: window.assetsRoot + "img/bradyBunch/seq4/carrie.jpg",				id:"mainMenuCarrie4"},
			{src: window.assetsRoot + "img/bradyBunch/seq4/charlotte.jpg",			id:"mainMenuCharlotte4"},
			{src: window.assetsRoot + "img/bradyBunch/seq4/vera.jpg",				id:"mainMenuVera4"},
			{src: window.assetsRoot + "img/bradyBunch/seq4/vidya.jpg",				id:"mainMenuVidya4"},
			{src: window.assetsRoot + "img/bradyBunch/seq4/wilma.jpg",				id:"mainMenuWilma4"},
			{src: window.assetsRoot + "img/bradyBunch/seq5/anne.jpg",				id:"mainMenuAnne5"},
			{src: window.assetsRoot + "img/bradyBunch/seq5/carrie.jpg",				id:"mainMenuCarrie5"},
			{src: window.assetsRoot + "img/bradyBunch/seq5/charlotte.jpg",			id:"mainMenuCharlotte5"},
			{src: window.assetsRoot + "img/bradyBunch/seq5/vera.jpg",				id:"mainMenuVera5"},
			{src: window.assetsRoot + "img/bradyBunch/seq5/vidya.jpg",				id:"mainMenuVidya5"},
			{src: window.assetsRoot + "img/bradyBunch/seq5/wilma.jpg",				id:"mainMenuWilma5"},
			{src: window.assetsRoot + "img/bradyBunch/seq6/anne.jpg",				id:"mainMenuAnne6"},
			{src: window.assetsRoot + "img/bradyBunch/seq6/carrie.jpg",				id:"mainMenuCarrie6"},
			{src: window.assetsRoot + "img/bradyBunch/seq6/charlotte.jpg",			id:"mainMenuCharlotte6"},
			{src: window.assetsRoot + "img/bradyBunch/seq6/vera.jpg",				id:"mainMenuVera6"},
			{src: window.assetsRoot + "img/bradyBunch/seq6/vidya.jpg",				id:"mainMenuVidya6"},
			{src: window.assetsRoot + "img/bradyBunch/seq6/wilma.jpg",				id:"mainMenuWilma6"},
			{src: window.assetsRoot + "img/bradyBunch/seq7/anne.jpg",				id:"mainMenuAnne7"},
			{src: window.assetsRoot + "img/bradyBunch/seq7/carrie.jpg",				id:"mainMenuCarrie7"},
			{src: window.assetsRoot + "img/bradyBunch/seq7/charlotte.jpg",			id:"mainMenuCharlotte7"},
			{src: window.assetsRoot + "img/bradyBunch/seq7/vera.jpg",				id:"mainMenuVera7"},
			{src: window.assetsRoot + "img/bradyBunch/seq7/vidya.jpg",				id:"mainMenuVidya7"},
			{src: window.assetsRoot + "img/bradyBunch/seq7/wilma.jpg",				id:"mainMenuWilma7"},

			//Recipe page
			{src: window.assetsRoot + "img/recipePage/recipePage.png",				id:"recipePageBG"},
			{src: window.assetsRoot + "img/recipePage/recipePageTop.png",			id:"recipePageTop"},
			{src: window.assetsRoot + "img/recipePage/recipePageBot.png",			id:"recipePageBot"},
			{src: window.assetsRoot + "img/recipePage/polaroid.png",				id:"recipePagePolaroid"},
			{src: window.assetsRoot + "img/recipePage/polaroidAnne.jpg",			id:"recipePagePolaroidAnne"},
			{src: window.assetsRoot + "img/recipePage/polaroidAnneRoll.jpg",		id:"recipePagePolaroidAnneRoll"},
			{src: window.assetsRoot + "img/recipePage/polaroidAnneTitle.png",		id:"recipePagePolaroidAnneTitle"},
			{src: window.assetsRoot + "img/recipePage/polaroidCarrie.jpg",			id:"recipePagePolaroidCarrie"},
			{src: window.assetsRoot + "img/recipePage/polaroidCarrieRoll.jpg",		id:"recipePagePolaroidCarrieRoll"},
			{src: window.assetsRoot + "img/recipePage/polaroidCarrieTitle.png",		id:"recipePagePolaroidCarrieTitle"},
			{src: window.assetsRoot + "img/recipePage/polaroidCharlotte.jpg",		id:"recipePagePolaroidCharlotte"},
			{src: window.assetsRoot + "img/recipePage/polaroidCharlotteRoll.jpg",	id:"recipePagePolaroidCharlotteRoll"},
			{src: window.assetsRoot + "img/recipePage/polaroidCharlotteTitle.png",	id:"recipePagePolaroidCharlotteTitle"},
			{src: window.assetsRoot + "img/recipePage/polaroidVera.jpg",			id:"recipePagePolaroidVera"},
			{src: window.assetsRoot + "img/recipePage/polaroidVeraRoll.jpg",		id:"recipePagePolaroidVeraRoll"},
			{src: window.assetsRoot + "img/recipePage/polaroidVeraTitle.png",		id:"recipePagePolaroidVeraTitle"},
			{src: window.assetsRoot + "img/recipePage/polaroidVidya.jpg",			id:"recipePagePolaroidVidya"},
			{src: window.assetsRoot + "img/recipePage/polaroidVidyaRoll.jpg",		id:"recipePagePolaroidVidyaRoll"},
			{src: window.assetsRoot + "img/recipePage/polaroidVidyaTitle.png",		id:"recipePagePolaroidVidyaTitle"},
			{src: window.assetsRoot + "img/recipePage/polaroidWilma.jpg",			id:"recipePagePolaroidWilma"},
			{src: window.assetsRoot + "img/recipePage/polaroidWilmaRoll.jpg",		id:"recipePagePolaroidWilmaRoll"},
			{src: window.assetsRoot + "img/recipePage/polaroidWilmaTitle.png",		id:"recipePagePolaroidWilmaTitle"},

			//UI
			{src: window.assetsRoot + "img/ui/backRoll.png",						id:"backRoll"},
			{src: window.assetsRoot + "img/ui/forwardRoll.png",						id:"forwardRoll"},
			{src: window.assetsRoot + "img/videoPage/largePlayButton.png",			id:"largePlayButton"},
			{src: window.assetsRoot + "img/hr.gif"}			
		];

		switch(lang) {
			case "en":
				preloaderManifest.push( {src: window.assetsRoot + "img/intro/skip.png", 			id:"introSkip"} );
				preloaderManifest.push( {src: window.assetsRoot + "img/intro/skip_rollover.png", 	id:"introSkipRoll"} );
				mainManifest.push( {src: window.assetsRoot + "breadCopy.json",						id:JSON_ID} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/leftTab.png",					id:"leftTab"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/leftTabDim.png",				id:"leftTabDim"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/middleTab.png",				id:"middleTab"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/middleTabDim.png",				id:"middleTabDim"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/rightTab.png",					id:"rightTab"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/rightTabDim.png",				id:"rightTabDim"} );
				break;

			case "fr":
				preloaderManifest.push( {src: window.assetsRoot + "img/intro/skip_fr.png", 			id:"introSkip"} );
				preloaderManifest.push( {src: window.assetsRoot + "img/intro/skip_rollover_fr.png", id:"introSkipRoll"} );
				mainManifest.push( {src: window.assetsRoot + "breadCopy_fr.json",					id:JSON_ID} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/leftTab_fr.png",				id:"leftTab"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/leftTabDim_fr.png",			id:"leftTabDim"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/middleTab_fr.png",				id:"middleTab"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/middleTabDim_fr.png",			id:"middleTabDim"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/rightTab_fr.png",				id:"rightTab"} );
				mainManifest.push( {src: window.assetsRoot + "img/ui/rightTabDim_fr.png",			id:"rightTabDim"} );
				break;
		}

		if(! Globals.isMobile) {
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_anne.jpg",				id:"videoPagePosterAnne"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_carrie.jpg",				id:"videoPagePosterCarrie"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_charlotte.jpg",			id:"videoPagePosterCharlotte"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_vera.jpg",				id:"videoPagePosterVera"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_vidya.jpg",				id:"videoPagePosterVidya"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_wilma.jpg",				id:"videoPagePosterWilma"} );			
		} else {
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_anne_mobile.jpg",		id:"videoPagePosterAnne"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_carrie_mobile.jpg",		id:"videoPagePosterCarrie"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_charlotte_mobile.jpg",	id:"videoPagePosterCharlotte"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_vera_mobile.jpg",		id:"videoPagePosterVera"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_vidya_mobile.jpg",		id:"videoPagePosterVidya"} );
			mainManifest.push( {src: window.assetsRoot + "img/videoPage/poster_wilma_mobile.jpg",		id:"videoPagePosterWilma"} );
		}


		if(Globals.shouldShowAnimJPGs) {
			//throw them in interspersed because they are by far the heaviest elements
			var manifestLength = mainManifest.length;
			mainManifest.splice(Math.floor(manifestLength/7*1), 0, {src: window.assetsRoot + "img/bradyBunch/seq1/anne.jpg",		id:"mainMenuAnne1_anim"});
			mainManifest.splice(Math.floor(manifestLength/7*2), 0, {src: window.assetsRoot + "img/bradyBunch/seq1/carrie.jpg",		id:"mainMenuCarrie1_anim"});
			mainManifest.splice(Math.floor(manifestLength/7*3), 0, {src: window.assetsRoot + "img/bradyBunch/seq1/charlotte.jpg",	id:"mainMenuCharlotte1_anim"});
			mainManifest.splice(Math.floor(manifestLength/7*4), 0, {src: window.assetsRoot + "img/bradyBunch/seq1/vera.jpg",		id:"mainMenuVera1_anim"});
			mainManifest.splice(Math.floor(manifestLength/7*5), 0, {src: window.assetsRoot + "img/bradyBunch/seq1/vidya.jpg",		id:"mainMenuVidya1_anim"});
			mainManifest.splice(Math.floor(manifestLength/7*6), 0, {src: window.assetsRoot + "img/bradyBunch/seq1/wilma.jpg",		id:"mainMenuWilma1_anim"});
		}

		//Add cache-buster
		for(var i=0; i < mainManifest.length; i++) {
			mainManifest[i].src += cb; 
		}



		//Public methods
		//*******************************************************************************************************
		
		//===============================================================
		this.preloadPreloader = function() {
			Analytics.trackEvent('intro', 'began pre-preloading');

			startTime = (new Date()).getTime();
			preloaderAssets = new createjs.LoadQueue(false);
			preloaderAssets.addEventListener('progress', onProgress);
			preloaderAssets.addEventListener('complete', onComplete);
			preloaderAssets.loadManifest(preloaderManifest);
		};
		//===============================================================



		//===============================================================
		this.preloadMain = function() {
			Analytics.trackEvent('intro', 'began loading');

			startTime = (new Date()).getTime();
			mainAssets = new createjs.LoadQueue(false);
			mainAssets.addEventListener('progress', onProgress);
			mainAssets.addEventListener('complete', onComplete);
			mainAssets.loadManifest(mainManifest);
		};
		//===============================================================



		//===============================================================
		this.get = function( id ) {
			if(mainAssets && mainAssets.getItem(id) !== undefined) {
				return mainAssets.getItem(id).src;
			} else if(preloaderAssets && preloaderAssets.getItem(id) !== undefined) {
				return preloaderAssets.getItem(id).src;
			}
			return null;
		};
		//===============================================================



		//===============================================================
		this.getAssetDimensions = function( id ) {
			if(mainAssets && mainAssets.getResult(id) !== null) {
				var asset = mainAssets.getResult(id);
				return {width: asset.width, height: asset.height};
			} else {
				return null;
			}
		};
		//===============================================================



		//===============================================================
		this.getJSON = function() {
			return mainAssets.getResult(JSON_ID);
		};
		//===============================================================

		//*******************************************************************************************************




		// Private methods
		//*******************************************************************************************************
		function calculateConnectionSpeed() {
			var endTime = (new Date()).getTime(),
				duration = (endTime - startTime) / 1000,
				bitsLoaded = MAIN_ASSETS_FILESIZE_BYTES * 8,
				speedBps = (bitsLoaded / duration).toFixed(2),
				speedKbps = (speedBps / 1024).toFixed(2),
				speedMbps = (speedKbps / 1024).toFixed(2);

			if(speedKbps < BANDWIDTH_MED) {
				Globals.videoQuality = 'low';
			} else if(speedKbps < BANDWIDTH_HIGH) {
				Globals.videoQuality = 'medium';
			} else if(speedKbps < BANDWIDTH_SUPER) {
				Globals.videoQuality = 'high';
			} else {
				Globals.videoQuality = 'super';
			}

			Analytics.trackEvent('intro', 'completed loading', null, duration);
			Analytics.trackEvent('intro', 'connection speed', null, speedKbps);
			Analytics.trackEvent('intro', 'video quality', Globals.videoQuality );

			console.log("Connection speed is: " + speedKbps + "kbps");
			console.log("Desired video quality is: " + Globals.videoQuality);
			if(Globals.forceVideoQuality) console.log("Forced video quality is: " + Globals.forceVideoQuality);
		}
		//*******************************************************************************************************





		//Event Listeners
		//*******************************************************************************************************
		
		//===============================================================
		function onProgress(e) {	
			self.dispatchEvent(self.PROGRESS, {progress: e.target.progress});
		}

		function onComplete(e) {
			e.target.removeEventListener('progress', onProgress);
			e.target.removeEventListener('complete', onComplete);


			if(e.target === preloaderAssets) {
				var endTime = (new Date()).getTime(),
					duration = (endTime - startTime) / 1000;

				Analytics.trackEvent('intro', 'completed pre-preloading', null, duration);
				self.preloaderLoaded = true;
				
			} else if(e.target === mainAssets) {
				calculateConnectionSpeed();

				//Add the fonts CSS to the DOM
				document.getElementsByTagName('head')[0].appendChild( mainAssets.getResult('fontsCSS') );

				self.allLoaded = true;
			}

			self.dispatchEvent(self.COMPLETE);
		}
		//===============================================================

		//*******************************************************************************************************
		
	};

	utils.extend(module, EventDispatcher);

	//Events
	module.prototype.PROGRESS = module.PROGRESS = 'assetsProgress';
	module.prototype.COMPLETE = module.COMPLETE = 'completeLoadQueue';

	return module;
});
