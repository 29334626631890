nfb.requirejs(
			['jquery', 'controllers/Assets', 'controllers/Analytics', 'controllers/NavMngr', 'controllers/AudioController', 'views/Subnav', 'views/screens/IntroLoader', 'views/screens/MainMenu', 'views/screens/AboutPage', 'views/screens/CreditsPage', 'views/screens/VideoPage', 'globals', 'templates', 'utils', 'cookies', 'address', 'tweenLite', 'nfbFramework', 'ntpagetag', 'gasocial', 'gatag', 'xdomain'],
	function( $,        Assets,               Analytics,               NavMngr,               AudioController,               Subnav,         IntroLoader,                 MainMenu,                 AboutPage,                 CreditsPage,                 VideoPage,                 Globals,   Templates,   utils ) {

	//HTML Elements
	var $footerLinks,
		$window = $(window);

	//Private misc vars
	var assets,
		audioController,
		currentSectionType,
		deepLinkTo,
		navMngr;

	init();

	//Iniialize. First function to run on site
	//====================================================================================================================
	function init() {
		//Should we allow the user to skip the intro?
		Globals.allowSkipIntro = true; //($.cookie('visitedBreadBefore') !== undefined);

		//Get the native scrollbar width
		Globals.scrollbarWidth = utils.scrollbarWidth();

		//Should we show the animated JPGs?
		// Globals.shouldShowAnimJPGs = ! utils.isMobile.any();
		Globals.shouldShowAnimJPGs = false; // no. no we shouldn't.

		//Is this a mobile device?
		Globals.isMobile = utils.isMobile.any();

		//Set the default easing function
		TweenLite.defaultEase = Power4.easeOut;

		//Controls loading and managing of all assets
		assets = new Assets();
		assets.addEventListener( Assets.PROGRESS, onPrePreloadProgress );
		assets.addEventListener( Assets.COMPLETE, onPrePreloadComplete );
		assets.preloadPreloader();

		//Handles deep-linking
		$.address.history(false);
		deepLinkTo = $.address.value().substr(1).toLowerCase();

		//Reset the mute status. Don't remember between page-views
		$.removeCookie('muted', { path: '/' });

		//Init the NFB Framework
		$.onf_nfb.stats.init({
			name: window.ntpgi_project
		});

		if($('html.embedded').length === 0) $.onf_nfb.menu.top({});
		
		$.onf_nfb.menu.bot({
			links: [
				{title: {fr: 'Recommencer', en: 'Start Over'}, url: null, preventDefault: true, cssClass: 'footerLink', callback: onClickStartOver},
				{title: {fr: 'À propos', en: 'About'}, url: null, preventDefault: true, cssClass: 'footerLink', callback: onClickAbout},
				{title: {fr: 'Crédits', en: 'Credits'}, url: null, preventDefault: true, cssClass: 'footerLink', callback: onClickCredits}
			],
			volume: {title: 'Mute'},
			fullscreen: {
				title: {fr: 'Plein écran', en: 'Fullscreen'},
				callback: null
			}
		});
		$footerLinks = $('.footerLink').css('visibility', 'hidden');


		//Manages page navigation
		navMngr = new NavMngr( assets );
		navMngr.addEventListener( NavMngr.GOING_TO_SECTION_TYPE, onGoingToSectionType );


		//Overwrite the default footer Share functionality
		$('#onf-share-opts a').click( onClickSocial );
		$('#onf-facebook').click( onClickFacebook );
		$('#onf-twitter').attr('href', 'https://twitter.com/intent/tweet' + '?url=' + encodeURIComponent(window.urlDomain) + '&text=' + encodeURIComponent( $('meta[property="twitter:description"]').attr('content') ) + '&hashtags=' + $('meta[property="twitter:hashtags"]').attr('content') );


		var queryparams = utils.parseQueryString();
		if(queryparams.quality !== undefined && queryparams.quality !== null) {
			switch(queryparams.quality.toLowerCase()) {
				case 0:
				case '0':
				case 'l':
				case 'low':
					Globals.forceVideoQuality = 'low';
					break;

				case 1:
				case '1':
				case 'm':
				case 'medium':
					Globals.forceVideoQuality = 'medium';
					break;

				case 2:
				case '2':
				case 'h':
				case 'high':
					Globals.forceVideoQuality = 'high';
					break;

				case 3:
				case '3':
				case 's':
				case 'super':
					Globals.forceVideoQuality = 'super';
					break;
			}
		}


		//Hack to fix the browser height when in landscape mode on an iPad in iOS 7
		if (
			navigator.userAgent.match(/iPad;.*CPU.*OS 7_\d/i) && 
			window.innerHeight !== document.documentElement.clientHeight
		) {
			var fixViewportHeight = function() {
				document.documentElement.style.height = window.innerHeight + "px";
				if (document.body.scrollTop !== 0) {
					window.scrollTo(0, 0);
				}
			};

			window.addEventListener("scroll", fixViewportHeight, false);
			window.addEventListener("orientationchange", fixViewportHeight, false);
			fixViewportHeight();

			document.body.style.webkitTransform = "translate3d(0,0,0)";
		}


		//Event listeners
		$window.on($.onf_nfb.events.volume, onClickVolumeControl);
		$window.on(MainMenu.CLICK_POLAROID, onClickPolaroid);
		$window.on(IntroLoader.ALL_ASSETS_LOADED, onAllAssetsLoaded);
		$window.on(IntroLoader.INTRO_ANIM_COMPLETE, onIntroAnimComplete);
		$window.on(Subnav.CLICKED_TAB, onClickedSubnavTab);
		$window.on(Subnav.CLICKED_BACK, onClickedSubnavPrev);
		$window.on(Subnav.CLICKED_FORWARD, onClickedSubnavNext);
		$window.on(AboutPage.CLOSE_ABOUT, onClickedCloseFooterSection);
		$window.on(CreditsPage.CLOSE_CREDITS, onClickedCloseFooterSection);
		$window.on(VideoPage.VIDEO_END, onVideoEnd);
		$window.on(VideoPage.CLOSE_VIDEO, onCloseVideo);
	}
	//====================================================================================================================



	//====================================================================================================================
	/*function initComscore() {
		window._comscore = window._comscore || [];
			
		(function() {
			var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
			s.src = (document.location.protocol === "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
			el.parentNode.insertBefore(s, el);
		})();
	}

	function initTwitter() {
		(function(d,s,id){
			var js,
				fjs=d.getElementsByTagName(s)[0],
				p=/^http:/.test(d.location) ? 'http':'https';

			if(!d.getElementById(id)) {
				js=d.createElement(s);
				js.id=id;
				js.src=p+'://platform.twitter.com/widgets.js';
				fjs.parentNode.insertBefore(js,fjs);
			}
		})(document, 'script', 'twitter-wjs');
	}

	function initFacebook() {
		window.fbAsyncInit = function() {
			FB.init({
				appId      : window.fbAppID,
				xfbml      : true,
				version    : 'v2.1'
			});
		};

		(function(d, s, id){
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {return;}
			js = d.createElement(s); js.id = id;
			js.src = "//connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}*/
	//====================================================================================================================



	//====================================================================================================================
	function renderTemplates() {
		var copy = assets.getJSON();

		copy.recipes.anne.emailBodyLinkDomain = window.urlDomain;
		copy.recipes.carrie.emailBodyLinkDomain = window.urlDomain;
		copy.recipes.vidya.emailBodyLinkDomain = window.urlDomain;
		copy.recipes.vera.emailBodyLinkDomain = window.urlDomain;
		copy.recipes.wilma.emailBodyLinkDomain = window.urlDomain;
		copy.recipes.charlotte.emailBodyLinkDomain = window.urlDomain;

		$('#anneRecipe').append( Templates.recipe( $.extend(copy.recipes.anne, copy.other) ) );
		$('#carrieRecipe').append( Templates.recipe( $.extend(copy.recipes.carrie, copy.other) ) );
		$('#vidyaRecipe').append( Templates.recipe( $.extend(copy.recipes.vidya, copy.other) ) );
		$('#veraRecipe').append( Templates.recipe( $.extend(copy.recipes.vera, copy.other) ) );
		$('#wilmaRecipe').append( Templates.recipe( $.extend(copy.recipes.wilma, copy.other) ) );
		$('#charlotteRecipe').append( Templates.recipe( $.extend(copy.recipes.charlotte, copy.other) ) );

		for(var i=0; i < Globals.womenOrder.length; i++) {
			var context = {index:i+1};
			html = Templates.video(context);

			$('#' + Globals.womenOrder[i] + 'Video').append(html);
		}
	}
	//====================================================================================================================




	//====================================================================================================================
	function navigateTo( section, slowAnim ) {
		if(navMngr.isValidSection(section)) {
			navMngr.gotoSection(section, slowAnim);
		} else if(! assets.allLoaded) {
			navMngr.gotoSection( Globals.sections.INTRO_LOADER);
		}
	}
	//====================================================================================================================




	//Preloading the preloader
	//====================================================================================================================
	function onPrePreloadProgress(e) {
		// console.log(Math.round(e.progress * 100) + "%");
	}

	function onPrePreloadComplete(e) {
		assets.removeEventListener(Assets.PROGRESS, onPrePreloadProgress);
		assets.removeEventListener(Assets.COMPLETE, onPrePreloadComplete);

		//Load bg music
		audioController = new AudioController();
		navMngr.audioController = audioController;

		//Load the Intro Loader
		setTimeout( function() {
			$('#branding').addClass('fadeOut');
		}, 2500 );
		setTimeout( navigateTo, 3000, Globals.sections.INTRO_LOADER );
	}

	function onAllAssetsLoaded(e) {
		$footerLinks.css('visibility', 'visible').hide().fadeIn('slow');

		// initComscore();
		// initTwitter();
		// initFacebook();

		renderTemplates();

		navMngr.initSubnav();
	}

	function onIntroAnimComplete(e) {
		$.cookie('visitedBreadBefore', true, {expires:365});
		// if(navMngr.canNavigate) $.address.value( '' );

		if(deepLinkTo !== '' && deepLinkTo !== null && navMngr.isValidSection(deepLinkTo) && deepLinkTo !== 'about' && deepLinkTo !== 'credits') {
			navigateTo( deepLinkTo, true );
			deepLinkTo = null;
		} else {
			navigateTo( Globals.sections.MAIN_MENU, true );
		}
	}
	//====================================================================================================================



	//====================================================================================================================
	function onClickFacebook(e) {
		e.preventDefault();

		if(FB) {
			FB.ui({
				method: 'share',
				href: window.urlDomain
			}, function(response){});
		}
	}

	function onClickPolaroid(e, href) {
		if(navMngr.canNavigate) $.address.value( href );
		navigateTo( href );
	}

	function onClickedSubnavTab(e, href) {
		if(navMngr.canNavigate) {
			if(href === 'menu') {
				$.address.value( '' );
			} else {
				$.address.value( href );
			}
		}
		if(href.indexOf('recipe') === -1) {
			navMngr.forceNextAnimDir = Globals.animDirections.FROM_LEFT;
		}
		navigateTo( href );
	}

	function onCloseVideo(e) {
		onClickedSubnavTab(e, 'menu');
	}

	function onClickedSubnavPrev(e, href) {
		if(navMngr.canNavigate) {
			navMngr.forceNextAnimDir = Globals.animDirections.FROM_LEFT;
			navigateTo( href );
			$.address.value( href );
		}
	}

	function onClickedSubnavNext(e, href) {
		if(navMngr.canNavigate) $.address.value( href );
		navigateTo( href );
	}

	function onClickStartOver(e) {
		e.preventDefault();
		if(navMngr.canNavigate) {
			Analytics.trackEvent('menu', 'start over');
			$.address.value( '' );
			navMngr.startOver();
		}
	}

	function onClickAbout(e) {
		e.preventDefault();
		navigateTo( Globals.sections.ABOUT );
	}

	function onClickCredits(e) {
		e.preventDefault();
		navigateTo( Globals.sections.CREDITS );
	}

	function onClickedCloseFooterSection(e) {
		navMngr.closeFooterSection();
	}


	function onVideoEnd(e) {
		navMngr.onVideoEnd(e);
	}


	function onGoingToSectionType(e) {
		currentSectionType = e.sectionType;
		if(currentSectionType === 'video') {
			audioController.setVideoPlaying(true);
		} else {
			audioController.setVideoPlaying(false);
		}
	}

	function onClickVolumeControl(e,orgEvent,target,muted) {
		audioController.globalMute = muted;

		if(muted) {
			Analytics.trackEvent('menu', 'muted');
			audioController.mute();
		} else if(! muted) {
			Analytics.trackEvent('menu', 'unmuted');
			audioController.unmute();
		}
	}

	function onClickSocial(e) {
		Analytics.trackSocial( $(this).data('tag'), 'share', '/', '/');
	}
	//====================================================================================================================
});
nfb.define("main", function(){});

