nfb.define('controllers/BackgroundMngr',['views/screens/Background', 'globals'], function( Background, globals ) {

	var module = function( assets ) {

		//Private vars
		var preloadBG,
			mainMenuBG,
			recipeBG,
			previousBG,
			currentBG;



		//Public methods
		//*******************************************************************************************************
		this.setBG = function( section ) {
			currentBG = null;

			switch( section ) {
				case globals.sections.INTRO_LOADER:
					if(! preloadBG) preloadBG = new Background( '#preloadBG', assets.get('introBG') );
					currentBG = preloadBG;
					break;

				case globals.sections.MAIN_MENU:
					if(! mainMenuBG) mainMenuBG = new Background( '#mainMenuBG', assets.get('cuttingBoard') );
					currentBG = mainMenuBG;
					break;

				case globals.sections.ANNE_RECIPE:
				case globals.sections.CARRIE_RECIPE:
				case globals.sections.VIDYA_RECIPE:
				case globals.sections.VERA_RECIPE:
				case globals.sections.WILMA_RECIPE:
				case globals.sections.CHARLOTTE_RECIPE:
					if(! mainMenuBG) mainMenuBG = new Background( '#recipeBG', assets.get('cuttingBoard') );
					currentBG = mainMenuBG;
					break;
			}
		};


		this.anim = function( dir, slowAnim ) {
			if(currentBG && currentBG !== previousBG) {
				currentBG.anim( dir, slowAnim );

				if(previousBG && dir === globals.animDirections.FROM_RIGHT) {
					previousBG.anim( globals.animDirections.TO_LEFT, slowAnim );

				} else if(previousBG && dir === globals.animDirections.FROM_LEFT) {
					previousBG.anim( globals.animDirections.TO_RIGHT, slowAnim );
				}
			}

			previousBG = currentBG;
		};
		//*******************************************************************************************************
	};

	return module;
});
