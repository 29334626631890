nfb.define('views/screens/CreditsPage',['utils', './ContentScreen'], function( utils, ContentScreen ) {

	var module = function( assets ) {
		
		//Public vars
		this.mainCntr = $('#creditsPage');

		//Private vars
		var self = this,
			$closeBtn = this.mainCntr.find('.closeBtn');

		

		//Init
		//*******************************************************************************************************
		this.mainCntr.css('backgroundImage', 'url(' + assets.get('creditsBG') + ')');		
		//*******************************************************************************************************




		//Public methods
		//*******************************************************************************************************
		this.start = function() {
			$closeBtn.click( onClickClose );
		};
		//*******************************************************************************************************



		//Event Listeners
		//*******************************************************************************************************
		function onClickClose( e ) {
			$(window).trigger( self.CLOSE_CREDITS );
			$closeBtn.off( 'click', onClickClose );
		}

		this.onResize = function(e) {

		};
		//*******************************************************************************************************
	};

	utils.extend(module, ContentScreen);

	module.prototype.CLOSE_CREDITS = module.CLOSE_CREDITS = 'closeCredits';

	return module;
});
