nfb.define('views/AnimatedJPG',[],function() {

	var module = function( div, width, height, numFrames, cols, numRows, loops ) {
		//Public vars
		this.fps = arguments.callee.defaultFPS;
		this.duplicateFrames = [];

		//Private vars
		var tick,
			currentFrame = 1,
			currentRow = 0,
			currentCol = 0,
			cuepoints = [],
			runBackwards = false,
			defaultWidth = width,
			defaultHeight = height,
			w = width,
			h = height,
			self = this;

		//Public methods
		//*******************************************************************************************************
		this.play = function() {
			start();
		};


		this.playBackwards = function() {
			if(currentFrame === 1) currentFrame = numFrames;
			runBackwards = true;
			currentRow = numRows -1;
			currentCol = (numFrames % (numRows-1)) -1;
			start();
		};


		this.nextFrame = function() {
			update();
		};


		this.pause = function() {
			clearInterval(tick);
		};


		this.setCuepoint = function( frameNum, cueName ) {
			cuepoints[cueName] = {"frameNum":frameNum, "triggered":false};
		};


		this.setScale = function( scale ) {
			w = Math.ceil(defaultWidth * scale);
			h = Math.ceil(defaultHeight * scale);
			
			// div.css('background-size', (w * cols) + 'px');
			div.attr('width', (w * cols))
			   .attr('height', (h * numRows));
			updatePosition();
		};


		this.kill = function() {
			if(tick !== null) clearInterval(tick);
			tick = null;
			cuepoints = null;
			self = null;
			this.duplicateFrames = null;
			this.fps = null;
		};
		//*******************************************************************************************************
		


		//Private methods
		//*******************************************************************************************************		
		function start() {
			tick = setInterval(update, Math.round(1000 / self.fps) );
		}
		

		function update() {
			if(! runBackwards) {
				currentFrame++;
			} else {
				currentFrame--;
			}

			var dupe = false;
			for(var i=0; i < self.duplicateFrames.length; i++) {
				if(! runBackwards && currentFrame > self.duplicateFrames[i][0] && currentFrame <= self.duplicateFrames[i][1]) {
					dupe = true;
					break;
				} else if(runBackwards && currentFrame < self.duplicateFrames[i][1] && currentFrame >= self.duplicateFrames[i][0]) {
					dupe = true;
					break;
				}
			}

			if(! dupe) {
				if(! runBackwards) {
					currentCol++;
				} else {
					currentCol--;
				}
			}
			

			if(currentFrame > numFrames) {
				currentFrame = 1;
				currentCol = 0;
				currentRow = 0;
				resetCuepoints();
			} else if(currentFrame < 1) {
				currentFrame = numFrames;
				currentRow = numRows - 1;
				currentCol = (numFrames % currentRow) -1;
				resetCuepoints();
			}

			if(! runBackwards && currentCol >= cols) {
				currentCol = 0;
				currentRow++;
			} else if(runBackwards && currentCol < 0) {
				currentRow--;
				currentCol = cols - 1;
			}

			updatePosition();

			checkCuepoints();

			if(! loops && currentFrame === numFrames && ! runBackwards) {
				currentFrame = numFrames;
				self.pause();
			} else if(! loops && currentFrame === 0 && runBackwards) {
				currentFrame = 1;
				self.pause();
			}
		}


		function updatePosition() {
			var x = w * currentCol * -1,
				y = h * currentRow * -1;

			//div.css('background-position', x + 'px ' + y + 'px');
			div.css({top: y + 'px', left: x + 'px'});
		}


		function checkCuepoints() {
			for( var prop in cuepoints) {
				if( (! runBackwards && currentFrame >= cuepoints[prop].frameNum && ! cuepoints[prop].triggered) ||
					(runBackwards && currentFrame <= cuepoints[prop].frameNum && ! cuepoints[prop].triggered) )  {
					cuepoints[prop].triggered = true;
					$(window).trigger(prop);
				}
			}
		}


		function resetCuepoints() {
			for( var prop in cuepoints) {
				cuepoints[prop].triggered = false;
			}
		}
		//*******************************************************************************************************
	};


	//Static vars
	module.defaultFPS = 24;

	return module;
});
