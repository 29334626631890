nfb.define('views/Subnav',['globals', 'utils', 'EventDispatcher', 'tweenLite'], function( globals, utils, EventDispatcher ) {

	var module = function( assets ) {

		//Private vars
		var $subnav = $('#subnav'),
			$allTab = $subnav.find('.leftTab'),
			$videoTab = $subnav.find('.middleTab'),
			$recipeTab = $subnav.find('.rightTab'),
			$allTabCopy = $allTab.find('.subnavCopy'),
			$videoTabCopy = $videoTab.find('.subnavCopy'),
			$recipeTabCopy = $recipeTab.find('.subnavCopy'),
			$backArrow = $('#backArrow'),
			$forwardArrow = $('#forwardArrow'),
			$window = $(window),
			navVisible = false,
			forcePrevNextVideo = false,
			self = this,
			lang = $('html.fr').length > 0 ? 'fr' : 'en',
			currentSection,
			lastWinW,
			shortTitlesAt = 500;



		//Init
		//*******************************************************************************************************
		$allTab.data('href', globals.sections.MAIN_MENU);

		$backArrow.click( onClickBack );
		$forwardArrow.click( onClickForward );

		//Apply the images
		if(assets) {
			$allTab.find('.subnavBG').css('backgroundImage', 'url(' + assets.get('leftTab') + ')');
			$allTab.find('.subnavBGDim').css('backgroundImage', 'url(' + assets.get('leftTabDim') + ')');
			$videoTab.find('.subnavBG').css('backgroundImage', 'url(' + assets.get('middleTab') + ')');
			$videoTab.find('.subnavBGDim').css('backgroundImage', 'url(' + assets.get('middleTabDim') + ')');
			$recipeTab.find('.subnavBG').css('backgroundImage', 'url(' + assets.get('rightTab') + ')');
			$recipeTab.find('.subnavBGDim').css('backgroundImage', 'url(' + assets.get('rightTabDim') + ')');
			$backArrow.css('backgroundImage', 'url(' + assets.get('backRoll') + ')');
			$forwardArrow.css('backgroundImage', 'url(' + assets.get('forwardRoll') + ')');
		}

		onResize();
		$window.resize( onResize );
		//*******************************************************************************************************





		//Public methods
		//*******************************************************************************************************
		this.updateNav = function( section, animSubtabs ) {
			setName( section );
			setState( section, animSubtabs );
			setPrevNext( section );
			showNav();
		};


		this.hideAll = function() {
			animOffTopNav();
			animOffSideNav();
		};


		this.hideTabs = function() {
			animOffTopNav();
		};


		this.gotoRecipe = function() {
			$recipeTab.click();
			forcePrevNextVideo = true;
		};
		//*******************************************************************************************************




		//Private methods
		//*******************************************************************************************************

		//===============================================================
		function showNav() {
			animOnTopNav();
			animOnSideNav();
		}
		//===============================================================



		//===============================================================
		function animOffTopNav() {
			animTopNav('-50px');
		}

		function animOnTopNav() {
			animTopNav(0);
		}

		function animTopNav( destY ) {
			TweenLite.to($subnav, 0.5, {top: destY});
		}



		function animOffSideNav() {
			var dest1 = ($backArrow.outerWidth(true) * -1) + 'px';
			var dest2 = ($forwardArrow.outerWidth(true) * -1) + 'px';
			animSideNav( dest1, dest2 );
		}

		function animOnSideNav() {
			animSideNav( 0, 0 );
		}

		function animSideNav( dest1, dest2 ) {
			if(! globals.isMobile) {
				TweenLite.to($backArrow, 0.5, {left: dest1});
				TweenLite.to($forwardArrow, 0.5, {right: dest2});
			}
		}
		//===============================================================



		//===============================================================
		function setName( section ) {
			currentSection = section;

			var name = getNameFromSection( section ),
				startsWithVowel,
				de,
				lcaseName;

			if(name) {
				lcaseName = name.toLowerCase();

				switch(lang) {
					case "en":
						$allTabCopy.html('All Stories');
						$videoTabCopy.html(name + "'s Story");
						$recipeTabCopy.html(name + "'s Recipe");
						break;

					case "fr":
						startsWithVowel = name.match(/^[aeiou]/i) ? true : false;
						de = startsWithVowel ? "d’" : "de ";

						$allTabCopy.html('Toutes les histoires');

						if(lastWinW > shortTitlesAt) {
							$videoTabCopy.html("Histoire " + de + name);
							$recipeTabCopy.html("Recette " + de + name);
						} else {

							$videoTabCopy.html("Histoire");
							$recipeTabCopy.html("Recette");
						}
						break;
				}
				
				$videoTab.data('href', lcaseName + '-video');
				$recipeTab.data('href', lcaseName + '-recipe');
			}
		}
		//===============================================================



		//===============================================================
		function setState( section, animSubtabs ) {
			switch(section) {
				case globals.sections.MAIN_MENU:
					setIn( $allTab, animSubtabs );
					setOut( $videoTab, animSubtabs );
					setOut( $recipeTab, animSubtabs );
					break;

				case globals.sections.ANNE_VIDEO:
				case globals.sections.CARRIE_VIDEO:
				case globals.sections.VIDYA_VIDEO:
				case globals.sections.VERA_VIDEO:
				case globals.sections.WILMA_VIDEO:
				case globals.sections.CHARLOTTE_VIDEO:
					setOut( $allTab, animSubtabs );
					setIn( $videoTab, animSubtabs );
					setOut( $recipeTab, animSubtabs );
					break;

				case globals.sections.ANNE_RECIPE:
				case globals.sections.CARRIE_RECIPE:
				case globals.sections.VIDYA_RECIPE:
				case globals.sections.VERA_RECIPE:
				case globals.sections.WILMA_RECIPE:
				case globals.sections.CHARLOTTE_RECIPE:
					setOut( $allTab, animSubtabs );
					setOut( $videoTab, animSubtabs );
					setIn( $recipeTab, animSubtabs );
					break;
			}
		}

		function setIn( tab, animate ) {
			var dur = animate ? 0.3 : 0;

			tab.off('click', onClickTab)
				.off('mouseenter', onRollTab)
				.off('mouseleave', onRollTab)
				.removeClass('dim')
				.removeClass('enabled')
				.detach().appendTo($subnav);

			TweenLite.to( tab, dur, {top: 0} );
		}

		function setOut( tab, animate ) {
			var dur = animate ? 0.3 : 0;
			TweenLite.to( tab, dur, {top: '-10px'} );

			tab.off('click', onClickTab)
				.off('mouseenter', onRollTab)
				.off('mouseleave', onRollTab);
				
			tab.click( onClickTab )
				.hover( onRollTab )
				.addClass('dim')
				.addClass('enabled');
		}
		//===============================================================



		//===============================================================
		function setPrevNext( section ) {
			var name = getNameFromSection( section ),
				lcaseName,
				prevWoman,
				nextWoman,
				womanIndex,
				sectionType;

			if(name) {
				lcaseName = name.toLowerCase();

				for(var i=0; i < globals.womenOrder.length; i++) {
					if(globals.womenOrder[i] === lcaseName) {
						womanIndex = i;
						break;
					}
				}

				sectionType = section.split('-')[1];
				if(forcePrevNextVideo) sectionType = "video";

				prevWoman = (womanIndex > 0) ? globals.womenOrder[i-1] : globals.womenOrder[globals.womenOrder.length-1];
				nextWoman = (womanIndex < globals.womenOrder.length-1) ? globals.womenOrder[i+1] : globals.womenOrder[0];

				$backArrow.data('href', prevWoman + '-' + sectionType);
				$forwardArrow.data('href', nextWoman + '-' + sectionType);
			}
		}
		//===============================================================

		//*******************************************************************************************************





		//Event Listeners
		//*******************************************************************************************************
		function onClickTab( e ) {
			forcePrevNextVideo = false;
			$(window).trigger( self.CLICKED_TAB, $(this).data('href') );
		}


		function onClickBack( e ) {
			$(window).trigger( self.CLICKED_BACK, $(this).data('href') );
		}


		function onClickForward( e ) {
			$(window).trigger( self.CLICKED_FORWARD, $(this).data('href') );
		}


		function onRollTab( e ) {
			switch(e.type) {
				case 'mouseenter':
					$(this).removeClass('dim');
					break;

				case 'mouseleave':
					$(this).addClass('dim');
					break;
			}
		}



		function onResize() {
			var w = $window.width();

			if(w === lastWinW) return;

			lastWinW = w;

			if(currentSection) {
				setName( currentSection );
			}
		}
		//*******************************************************************************************************




		//Helper methods
		//*******************************************************************************************************
		function getNameFromSection( section ) {
			switch(section) {
				case globals.sections.ANNE_VIDEO:
				case globals.sections.ANNE_RECIPE:
					return 'Anne';

				case globals.sections.CARRIE_VIDEO:
				case globals.sections.CARRIE_RECIPE:
					return 'Carrie';

				case globals.sections.VIDYA_VIDEO:
				case globals.sections.VIDYA_RECIPE:
					return 'Vidya';

				case globals.sections.VERA_VIDEO:
				case globals.sections.VERA_RECIPE:
					return 'Vera';

				case globals.sections.WILMA_VIDEO:
				case globals.sections.WILMA_RECIPE:
					return 'Wilma';

				case globals.sections.CHARLOTTE_VIDEO:
				case globals.sections.CHARLOTTE_RECIPE:
					return 'Charlotte';
			}

			return null;
		}
		//*******************************************************************************************************
	};

	utils.extend(module, EventDispatcher);

	//Events
	module.prototype.CLICKED_TAB = module.CLICKED_TAB = 'subnav_clickedTab';
	module.prototype.CLICKED_BACK = module.CLICKED_BACK = 'subnav_clickedBack';
	module.prototype.CLICKED_FORWARD = module.CLICKED_FORWARD = 'subnav_clickedForward';

	return module;
});
