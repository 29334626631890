nfb.define('views/screens/AboutPage',['utils', './ContentScreen', 'controllers/Analytics'], function( utils, ContentScreen, Analytics ) {

	var module = function( assets ) {
		
		//Public vars
		this.mainCntr = $('#aboutPage');

		//Private vars
		var self = this,
			$closeBtn = this.mainCntr.find('.closeBtn');


		//Init
		//*******************************************************************************************************
		this.mainCntr.css('backgroundImage', 'url(' + assets.get('aboutBG') + ')');

		this.mainCntr.find('.facebookGroup').click( function(e) {
			Analytics.trackEvent('about', 'clicked facebook link');
		});
		//*******************************************************************************************************





		//Public methods
		//*******************************************************************************************************
		this.start = function() {
			$closeBtn.click( onClickClose );
		};
		//*******************************************************************************************************



		//Event Listeners
		//*******************************************************************************************************
		function onClickClose( e ) {
			$(window).trigger( self.CLOSE_ABOUT );
			$closeBtn.off( 'click', onClickClose );
		}

		this.onResize = function(e) {

		};
		//*******************************************************************************************************
	};

	utils.extend(module, ContentScreen);

	module.prototype.CLOSE_ABOUT = module.CLOSE_ABOUT = 'closeAbout';

	return module;
});
