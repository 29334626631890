nfb.define('views/screens/ContentScreen',['utils', 'EventDispatcher', 'globals', 'tweenLite'], function( utils, EventDispatcher, globals ) {

	var module = function() {
		//Public vars
		this.mainCntr = null;
		this.sectionType = "";
		this.events = {};
	};

	utils.extend(module, EventDispatcher);


	module.prototype.init = function() {
		
	};

	module.prototype.start = function() {
		
	};

	module.prototype.pause = function() {
		
	};

	module.prototype.resume = function() {
		
	};

	module.prototype.kill = function() {
		
	};

	module.prototype.onResize = function() {
		
	};


	module.prototype.anim = function( dir, slowAnim ) {
		var outerWrapper = $('#content'),
			w = outerWrapper.outerWidth(),
			h = outerWrapper.outerHeight(),
			left,
			top,
			destX,
			destY,
			destDisplay;

		switch(dir) {
			case globals.animDirections.FROM_RIGHT:
				left = w;
				top = 0;
				destX = 0;
				destY = 0;
				destDisplay = 'block';
				break;

			case globals.animDirections.FROM_LEFT:
				left = w * -1;
				top = 0;
				destX = 0;
				destY = 0;
				destDisplay = 'block';
				break;

			case globals.animDirections.FROM_BOTTOM:
				left = 0;
				top = h;
				destX = 0;
				destY = 0;
				destDisplay = 'block';
				break;

			case globals.animDirections.TO_RIGHT:
				left = parseInt(this.mainCntr.css('left'), 10);
				top = parseInt(this.mainCntr.css('top'), 10);
				destX = w;
				destY = 0;
				destDisplay = 'none';
				break;

			case globals.animDirections.TO_LEFT:
				left = parseInt(this.mainCntr.css('left'), 10);
				top = parseInt(this.mainCntr.css('top'), 10);
				destX = w * -1;
				destY = 0;
				destDisplay = 'none';
				break;

			case globals.animDirections.TO_BOTTOM:
				left = parseInt(this.mainCntr.css('left'), 10);
				top = parseInt(this.mainCntr.css('top'), 10);
				destX = 0;
				destY = h;
				destDisplay = 'none';
				break;

			case globals.animDirections.FADE_IN:
				left = 0;
				top = 0;
				destX = 0;
				destY = 0;
				destDisplay = 'block';
				break;
		}

		TweenLite.killTweensOf(this.mainCntr);
		
		this.mainCntr.css({left:left + 'px',
						   top: top + 'px',
						   width: '100%',
						   height: '100%'});

		if(dir !== globals.animDirections.FADE_IN) {
			var dur = slowAnim ? 2 : 1;

			TweenLite.to(this.mainCntr, dur, {
				left: destX,
				top: destY,
				ease: Power3.easeInOut,
				onComplete: this.onCompleteAnim,
				onCompleteParams: [destDisplay],
				onCompleteScope: this});
		
		} else {
			TweenLite.fromTo(this.mainCntr, 0.5, {alpha:0}, {
				autoAlpha: 1,
				ease: Linear.easeNone,
				onComplete: this.onCompleteAnim,
				onCompleteParams: [destDisplay],
				onCompleteScope: this});
		}

		switch(dir) {
			case globals.animDirections.FROM_RIGHT:
			case globals.animDirections.FROM_LEFT:
			case globals.animDirections.FROM_BOTTOM:
			case globals.animDirections.FADE_IN:
				this.onResize();
				// setTimeout(this.onResize, 100);
				$(window).resize(this.onResize);
				break;

			default:
				$(window).off('resize', this.onResize);
		}
		
	};


	module.prototype.onCompleteAnim = function( destDisplay ) {
		if(destDisplay === 'none') {
			this.mainCntr.css({width: 0,
							   height: 0})
						.scrollTop(0);
		} else {
			this.mainCntr.css({width: '100%',
							   height: '100%'});
		}
		
		this.dispatchEvent( this.COMPLETE );
	};


	//Events
	module.prototype.COMPLETE = module.COMPLETE = 'completeAnimContentScreen';


	return module;
});
