nfb.define( 'controllers/AnimatedJPGMngr',[],function() {

	var module = function() {

		//Public vars
		this.fps = arguments.callee.defaultFPS;

		//Private vars
		var anims = [],
			tick,
			self = this,
			numAnims;


		//Public methods
		//*******************************************************************************************************
		this.addJPGAnim = function( jpgAnim ) {
			anims.push(jpgAnim);
			numAnims = anims.length;
		};

		this.play = function() {
			start();
		};

		this.pause = function() {
			stop();
		};

		this.kill = function() {
			if(tick !== null) clearInterval(tick);
			if(numAnims !== null) {
				for(var i=0; i < numAnims; i++) {
					anims[i].kill();
				}
			}
			anims = null;
			tick = null;
			self = null;
			numAnims = null;
			this.fps = null;
		};
		//*******************************************************************************************************




		//Private methods
		//*******************************************************************************************************
		function start() {
			tick = setInterval(update, Math.round(1000 / self.fps) );
		}

		function stop() {
			clearInterval(tick);
		}

		function update() {
			for(var i=0; i < numAnims; i++) {
				anims[i].nextFrame();
			}
		}
		//*******************************************************************************************************
	};

	//Static vars
	module.defaultFPS = 24;

	return module;
});
