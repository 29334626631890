nfb.define('utils',[],function() {

	// Avoid `console` errors in browsers that lack a console.
	//====================================================================================================================
	(function() {
		var method;
		var noop = function () {};
		var methods = [
			'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
			'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
			'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
			'timeStamp', 'trace', 'warn'
		];
		var length = methods.length;
		var console = (window.console = window.console || {});

		while (length--) {
			method = methods[length];

			// Only stub undefined methods.
			if (!console[method]) {
				console[method] = noop;
			}
		}
	}());
	//====================================================================================================================




	//Polyfill in case bind() doesn't exist in older browsers
	//====================================================================================================================
	if (!Function.prototype.bind) {
		Function.prototype.bind = function (oThis) {
			if (typeof this !== "function") {
				// closest thing possible to the ECMAScript 5 internal IsCallable function
				throw new TypeError("Function.prototype.bind - what is trying to be bound is not callable");
			}

			var aArgs = Array.prototype.slice.call(arguments, 1),
				fToBind = this,
				fNOP = function () {},
				fBound = function () {
					return fToBind.apply( (this instanceof fNOP && oThis) ? this : oThis,
											aArgs.concat(Array.prototype.slice.call(arguments)));
				};

			fNOP.prototype = this.prototype;
			fBound.prototype = new fNOP();

			return fBound;
		};
	}
	//====================================================================================================================



	return {
		//Inheritance helper
		extend: function(Child, Parent) {
			var F = function () {};
			F.prototype = Parent.prototype;
			Child.prototype = new F();
			Child.prototype.constructor = Child;
			Child.uber = Parent.prototype;
		},

		capitalize: function( str ) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		},

		parseQueryString: function() {
			var params = {},
				hashString = location.hash.substring(1),
				queryString = location.search.substring(1),
				regex = /([^&])+[^&]/g,
				split,
				m;
			var indexQMark = hashString.indexOf('?');
				if(indexQMark !== -1) {
				queryString = hashString.substr(indexQMark+1);
				hashString = hashString.substring(0, indexQMark);
			}

			m = regex.exec(hashString);
			while (m) {
				split = m[0].split('=');
				if(split.length < 2) split[1] = null;
				params[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
				m = regex.exec(hashString);
			}

			m = regex.exec(queryString);
			while (m) {
				split = m[0].split('=');
				if(split.length < 2) split[1] = null;
				params[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
				m = regex.exec(queryString);
			}

			return params;
		},

		// Browser-sniffing
		isMobile: {
			Android: function() {
				return navigator.userAgent.match(/Android/i);
			},
			BlackBerry: function() {
				return navigator.userAgent.match(/BlackBerry/i);
			},
			iOS: function() {
				return navigator.userAgent.match(/iPhone|iPad|iPod/i);
			},
			Opera: function() {
				return navigator.userAgent.match(/Opera Mini/i);
			},
			Windows: function() {
				return navigator.userAgent.match(/IEMobile/i);
			},
			any: function() {
				return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
			}
		},

		//Get the native scrollbar width
		scrollbarWidth: function() { 
			var $c = $("<div style='position:absolute; top:-10000px; left:-10000px; width:100px; height:100px; overflow:scroll;'></div>").appendTo("body");
			var dim = {
				width: $c.width() - $c[0].clientWidth,
				height: $c.height() - $c[0].clientHeight
			};
			$c.remove();
			return dim.width;
		}
	};
});
